import React from "react";

const anor = window.location.origin + "/assets/img/anor.png";
export default () => {
  return (
    <div className="container" style={{ marginTop: 20, marginBottom: 20 }}>
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div className="row d-flex justify-content-around">
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <img src={anor} height="350px" />
            </div>
          </div>
          <p>
            <strong>
              O'zbekiston Anorchilar assosiasiyasining asosiy maqsadi
            </strong>
            – qishloq xo‘jaligi ekinlarini yetishtirishda ilg‘or tajribalarni
            ommalashtirish, mahsulotlarni standartlashtirish va
            sertifikasiyalash, istiqbolli navlarni yaratish, jahon bozori
            konyunkturasini o‘rganish, mahsulotlarning tijorat namunalarini
            yaratish, ularni brendlash, xalqaro ko‘rgazmalarda ishtirok etish,
            ishlab chiqaruvchilarni mamlakatimizda rag‘batlantirish va
            qo‘llab-quvvatlash. tashqi bozorlar, mahsulotlarni qayta ishlash
            bo‘yicha investisiya loyihalarini o‘rganishdan iboratdir.
          </p>
          <p>
            Bu vazifalarning amalga oshirilishi bo‘lajak assosiasiya a'zolariga
            sohaning barqaror rivojlanishini ta'minlash va uni{" "}
            <strong> 5 yillik</strong> rivojlantirish Strategiyasini
            tayyorlashning imkonini beradi
          </p>
          <p>
            <strong> Anorchilar assosiasiyasining asosiy vazifalari </strong> -
            tarmoqni o‘zgartirishni jadallashtirish uchun amaldagi qonunchilikni
            takomillashtirishdan iborat bo‘lib, bu mamlakatimiz qishloq
            xo‘jaligining boshqa ko‘plab tarmoqlari uchun namuna bo‘lishi O‘zbek
            anori yaqin kelajakda jahon ishlab chiqarishi yetakchilarini quvib
            o‘tib Yevropa va Osiyo hamda boshqa qit'alar iste'molchilarini
            xursand qilishdir
          </p>

          <p style={{ fontWeight: "bold" }}>
            2022 yil 25 iyulda tashkil etildi, muddati cheksiz, ta'sischilar va
            a'zolar tomonidan moliyalashtiriladi
          </p>
          <p style={{ fontWeight: "bold" }}>
            A'zolari - anor yetishtiruvchi uy xujaliklari, fermerlar,
            kooperativlar, kayta ishlovchi xususiy korxonalar, donor
            tashkilotlar
          </p>
          <p style={{ fontWeight: "bold" }}>
            Yuridik manzil: Toshkent shaxar, Mirzo Ulug‘bek tumani, Niyozbek
            yo‘li ko‘chasi 30 uy
          </p>

          <p style={{ fontWeight: "bold" }}>
            Web sayt: <a href="https://anorchi.uz/" target="_blank">anorchi.uz</a>
          </p>

          <br />
          <h3 style={{ textAlign: "center" }}>
            Loyihaning ma'sul hodimlari haqida qisqacha ma'lumot
          </h3>

          <div className="col-12">
            <table style={{ width: "100%", border: " 1px solid  black" }}>
              <tr style={{ borderBottom: " 1px solid  black" }}>
                <th style={{ padding: "7px" }}>№</th>

                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "7px",
                  }}
                >
                  Lavozim
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "7px",
                  }}
                >
                  Mas'ul xodim
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "7px",
                  }}
                >
                  Telefon raqami
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "7px",
                  }}
                >
                  Email pochtasi
                </td>
              </tr>
              <tr style={{ borderBottom: " 1px solid  black" }}>
                <th style={{ padding: "7px" }}>1</th>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "7px",
                  }}
                >
                  Boshqaruv raisi
                </td>
                <td
                  style={{
                    borderLeft: " 1px solid  black",
                    padding: "7px",
                  }}
                >
                  Masturaxon Sayfitdinova
                </td>
                <td
                  style={{
                    borderLeft: " 1px solid  black",
                    padding: "7px",
                  }}
                >
                  +99899 1742340
                  <br />
                  +99890 2741440
                </td>
                <td
                  style={{
                    borderLeft: " 1px solid  black",
                    padding: "7px",
                  }}
                >
                  masturaxon.sayfutdinova@mail.ru
                </td>
              </tr>
              <tr style={{ borderBottom: " 1px solid  black" }}>
                <th style={{ padding: "7px" }}>2</th>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "7px",
                  }}
                >
                  Boshqaruv raisi o‘rinbosari
                </td>
                <td
                  style={{
                    borderLeft: " 1px solid  black",
                    padding: "7px",
                  }}
                >
                  Shermatov Baxtiyor
                </td>
                <td
                  style={{
                    borderLeft: " 1px solid  black",
                    padding: "7px",
                  }}
                >
                  +99833 2060058
                  <br />
                  +99890 3181234
                </td>
                <td
                  style={{
                    borderLeft: " 1px solid  black",
                    padding: "7px",
                  }}
                >
                  bakhtiyor091@gmail.com
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid  black" }}>
                <th style={{ padding: "7px" }}>3</th>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "7px",
                  }}
                >
                  Boshqaruv raisi o‘rinbosari
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "7px",
                  }}
                >
                  Nematov Isroil
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "7px",
                  }}
                >
                  +998972450657
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "7px",
                  }}
                >
                  -
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="col-sm-12 col-md-3"></div>
      </div>
    </div>
  );
};
