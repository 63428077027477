import React from "react";
import Loader from "../Loader/Loader";
import { createReadableDate } from "../../utils/index";
import { Link } from "react-router-dom";

class TopNews extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      loading: false,
    };
  }

  componentDidMount() {
    const { events } = this.props;
    if (!events) {
    }
  }
  render() {
    const { loading } = this.state;
    const { articles } = this.props;

    let ar = null;
    if (!articles) {
      ar = this.state.articles;
    } else {
      ar = articles;
    }
    return (
      <>
        <ul id="events-list" className="d-flex flex-column p-0">
          {!loading ? (
            Array.isArray(ar) && ar.length > 0 ? (
              ar.map((article) => {
                return (
                  <li key={article.id} className="d-flex flex-column my-1 px-1 py-1">
                    <Link
                      to={`/article/${article.id}`}
                    >
                      <span className="event-title">{article.title}</span>
                      <span className="event-date">
                        {createReadableDate(article.created_at)}
                      </span>
                    </Link>
                  </li>
                );
              })
            ) : (
              ""
            )
          ) : (
            <Loader />
          )}
        </ul>
      </>
    );
  }
}

export default TopNews;
