import React from "react";
import { Link } from "react-router-dom";
import { createReadableDate } from "../../utils";

function LeftNewsCard(props) {
  const { article } = props;
  if (!article) {
    return null;
  }
  const {
    id,
    title,
    subtitle,
    created_at,
    preview_image,
  } = article;
  const imagePreview = preview_image?.includes("http")
    ? preview_image
    : "https://dummyimage.com/480/ced4da/6c757d.jpg";
  return (
    <Link
      to={`/article/${id}`}
      className="d-block "
      style={{ color: "inherit" }}
    >
      <div className="card card-left " >
        <div className="card-image">
          <img src={imagePreview} alt={title} />
        </div>
        <div className="card-info mt-2 p-3">
          <span className="card-stats " >
            <span>{createReadableDate(created_at)}</span>
          </span>
          <h3 className="card-title" >{title}</h3>

        </div>
      </div>
    
    </Link>
    
  );
}

export default LeftNewsCard;
