import React from "react";
import { NavLink, Link } from "react-router-dom";
import { priorityNavigation } from "../../utils";
const logo = window.location.origin + "/assets/img/logo.png";
const eu = window.location.origin + "/assets/img/eu.jpg";
const world = window.location.origin + "/assets/img/word.jpeg";
const uz = window.location.origin + "/assets/img/Uzbekistan_Gerb.png";
const iscad = window.location.origin + "/assets/img/iscad.jpg";
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  createDropdown(types) {
    let articleRouter = [];
    for (const [key, value] of Object.entries(types)) {
      articleRouter.push(
        <li className="nav-item">
          <Link exact to={`/blog?article_type=${value}`} className="nav-link">
            {key}
          </Link>
        </li>
      );
    }

    return articleRouter;
  }
  removenav() {
    const nav = document.getElementById("priority-nav");
    const moreBtn = nav.querySelector("#priority-nav span.more-dots");
    const navHiddenList = nav.querySelector("#priority-nav .hidden-nav-list");
    if (navHiddenList.classList.contains("hidden")) {
      moreBtn.classList.add("open");
    } else {
      moreBtn.classList.remove("open");
    }
    navHiddenList.classList.toggle("hidden");
  }
  componentDidMount() {
    priorityNavigation();
  }
  render() {
    return (
      <>
        <header className={this.state.isOpen ? "open" : ""}>
          <div className="logo-header">
            <div className="logos-wrapper container">
              <div className="row">
                <div
                  className="col-md-6 d-flex align-items-center"
                  style={{ backgroundColor: "#fff" }}
                >
                  <div className="d-flex flex-column">
                    <a href="/">
                      <img
                        src={logo}
                        className="logo-shirinmeva"
                        alt="Meva-sabzavotchilik marketingi loyihasining rasmiy brendi"
                      />
                    </a>
                    <p className="logo-text">
                      Meva-sabzavotchilik marketingi loyihasining rasmiy brendi
                    </p>
                  </div>
                </div>
                <div className="col-md-6 d-none d-md-flex justify-content-end">
                  <div style={{ alignSelf: "end" }} >
                    <a href="https://europa.eu/european-union/index_en">
                      <img
                        style={{ height: "92px" }}
                        src={eu}
                        className=" m-2"
                      />
                    </a>
                  </div>
                  <div style={{ alignSelf: "end" }}>
                    <a href="https://www.worldbank.org/en/home">
                      <img
                        style={{ height: "90px" }}
                        src={world}
                        className=" m-2"
                      />
                    </a>

                  </div>
                  <div style={{ alignSelf: "end" }}>
                    <a href="https://www.agro.uz/">
                      <img
                        style={{ height: "90px" }}
                        src={uz}
                        className=" m-2"
                      />
                    </a>
                  </div>
                  <div style={{ alignSelf: "end" }}>
                    <a href="http://www.uzaifsa.uz/">
                      <img style={{ height: "80px" }} src={iscad} />
                    </a>
                  </div>
                  <div className="d-flex flex-column "></div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <nav
          id="priority-nav"
          className="container-fluid d-flex flex-row mb-5 position-sticky"
          style={{ top: 0, zIndex: 1920 }}
        >
          <span id="background-overlay" className="d-block d-md-none"></span>
          <ul className="container-fluid nav-list" style={{ marginBottom: 0 }}>
            <li className="nav-item" onClick={this.removenav}>
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-link"
              >
                Bosh sahifa
              </NavLink>
            </li>
            <li className="nav-item" onClick={this.removenav}>
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-link"
              >
                Loyiha haqida
              </NavLink>
            </li>

            {/* <li className="nav-item" onClick={this.removenav}>
              <NavLink
                exact
                isActive={(match, location) =>
                  location.pathname + location.search ===
                  `/blog?article_type=news`
                }
                to="/blog?article_type=news"
                activeClassName="active"
                className="nav-link"
              >
                Yangiliklar
              </NavLink>
            </li> */}

            <li className="nav-item" onClick={this.removenav}>
              <NavLink
                  exact
                  to={`/blog?article_type=news`}
                  activeClassName="active"
                  className="nav-link"
              >
                Yangiliklar
              </NavLink>
            </li>

          {/*  <li className="nav-item nav-dropdown" onClick={this.removenav}>
              <Link
                exact
                to="/blog?article_type=news"
                activeClassName="active"
                className="nav-link"
              >
                Yangiliklar
              </Link>
              <ul className="dropdown">
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact to="/blog?article_type=news" className="nav-link">
                    Yangiliklar
                  </Link>
                </li>
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact to={`/fruit-vegetable`} className="nav-link">
                    Meva-sabzavotchilik yangiliklari
                  </Link>
                </li>
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact to={`/project-news`} className="nav-link">
                    Loyihalar yangiliklari
                  </Link>
                </li>
              </ul>
            </li>*/}

            <li className="nav-item" onClick={this.removenav}>
              <NavLink
                exact
                to={`/contents`}
                activeClassName="active"
                className="nav-link"
              >
                Jurnal
              </NavLink>
            </li>
            { <li className="nav-item" onClick={this.removenav}>
              <NavLink
                exact
                to={`/gallery`}
                activeClassName="active"
                className="nav-link"
              >
                Foto Galereya
              </NavLink>
            </li> }
            {/* <li className="nav-item" onClick={this.removenav}>
              <NavLink
                exact
                to={`/companies`}
                activeClassName="active"
                className="nav-link"
              >
                Kompaniyalar
              </NavLink>
            </li> */}
            <li className="nav-item nav-dropdown" onClick={this.removenav}>
              <Link
                exact
                to={`/anor`}
                activeClassName="active"
                className="nav-link"
              >
                Anorchilar Assotsiatsiyasi
              </Link>
              <ul className="dropdown">
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact to={`/anor`} className="nav-link">
                    Assotsiatsiya  haqida
                  </Link>
                </li>
               {/*   <li className="nav-item" onClick={this.removenav}>
                                    <Link exact to={`/festival`} className="nav-link">
                                        Festival
                                    </Link>
                </li>*/}
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact to={`/pomegranates-news`} className="nav-link">
                    Assotsiatsiya yangiliklari
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li className="nav-item" onClick={this.removenav}>
              <Link exact to="/videos" activeClassName="active" className="nav-link">
                Videolar
              </Link>
            </li> */}
            {/* <li className="nav-item" onClick={this.removenav}>
              <Link exact to={`/maps`} className="nav-link">
                Xarita
              </Link>
            </li> */}


            <li className="nav-item nav-dropdown" onClick={this.removenav}>
              <Link
                exact
                to={`/events`}
                activeClassName="active"
                className="nav-link"
              >Loyiha materiallari
              </Link>
              <ul className="dropdown">
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact to={`/analysis`} className="nav-link">
                    Tahliliy maqolalar
                  </Link>
                </li>
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact to={`/statistics`} className="nav-link">
                    Hisobotlar
                  </Link>
                </li>
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact to={`/manuals`} className="nav-link">
                    Qo'llanmalar
                  </Link>
                </li>
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact
                    to={`/presentations`}
                    activeClassName="active"
                    className="nav-link">
                    Prezentatsiyalar
                  </Link>
                </li>
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact
                    to={`/videos`}
                    activeClassName="active"
                    className="nav-link">
                    Videolar
                  </Link>
                </li>

                <li className="nav-item" onClick={this.removenav}>
                  <Link exact
                    to={`/maps`}
                    activeClassName="active"
                    className="nav-link">
                    Xarita
                  </Link>
                </li>
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact to={`/events`} className="nav-link">
                    Tadbirlar
                  </Link>
                </li>

                <li className="nav-item" onClick={this.removenav}>
                  <Link exact to={`/companies`} className="nav-link">
                    Kompaniyalar
                  </Link>
                </li>
                <li className="nav-item" onClick={this.removenav}>
                  <Link exact to={{pathname:"https://docs.google.com/spreadsheets/d/1HVkfwY8yZcD5HKO6fKsoFK2XrU_vd0nYSGPnQpLeFow/edit?usp=sharing"}} target="_blank" className="nav-link">
                    Korxonalar bazasi
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item" onClick={this.removenav}>
              <NavLink
                exact
                to={`/ads`}
                activeClassName="active"
                className="nav-link"
              >
                Reklamalar
              </NavLink>
            </li>
            <li className="nav-item" onClick={this.removenav}>
              <NavLink
                to={{ pathname: "https://app.shirinmeva.uz/#/" }} target="_blank"
                activeClassName="active"
                className="nav-link"
              >
                ShirinMeva mobil ilovasi
              </NavLink>
            </li>
            {/* <li className="nav-item" onClick={this.removenav}>
              <NavLink
                exact
                to={`/presentations`}
                activeClassName="active"
                className="nav-link"
              >
                Prezintatsiyalar
              </NavLink>
            </li> */}
            {/* <li className="nav-item" onClick={this.removenav}>
              <NavLink
                exact
                to={`/anor`}
                activeClassName="active"
                className="nav-link"
              >
                Anorchilar Assotsiatsiya
              </NavLink>
            </li>
            <li className="nav-item" onClick={this.removenav}>
              <NavLink
                exact
                to={`/festival`}
                activeClassName="active"
                className="nav-link"
              >
                Ro'yxatdan o'tish
              </NavLink>
            </li> */}
          </ul>
          <ul className="hidden-nav-list hidden"></ul>
          <div className="d-flex align-items-center mr-3">
            <span className="more-dots">
              <span />
            </span>
          </div>
        </nav>
      </>
    );
  }
}
