import React from "react";
import { Line } from "react-chartjs-2";

function set_chart(chart) {
  var last = [];
  last.push(chart);
  return last;
}
function ChartCardUZB(props) {
  let { chart } = props;
  let product;
  let alllist = [];
  let pricetemp = [];
  let datetemp = [];

  let randomColor = "#" + ((1 << 24) * Math.random() | 0).toString(16);
  let dataset = {
    label: "",
    data: [],
    fill: false,
    backgroundColor: randomColor,
    borderColor: randomColor,
  };
  if (!chart) {
    return null;
  }
  chart.map((x) => {
    x.prices.sort((a, b) => new Date(a.date) - new Date(b.date))
    x.prices.map(y => {
      pricetemp.push(y.price)
    })
    let random = "#" + ((1 << 24) * Math.random() | 0).toString(16);
    product = x.product;
    dataset = {
      label: x.country,
      data: pricetemp,
      fill: false,
      backgroundColor: random,
      borderColor: random,
    }
    alllist = [...set_chart(dataset), ...alllist];
    dataset = {};
    pricetemp = []
  });
  chart[0].prices.map(z => {
    datetemp.push(z.date)
  })
  datetemp = datetemp.sort();
  datetemp = datetemp.map((x) => {
    return new Date(x).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
  });

  let  data = {
    labels: datetemp,
    datasets: alllist
  };
  let lineOptions = {
    scales: {
      yAxes: {
        ticks: {
          callback: function (value, index, values) {
            return '$ ' + parseFloat(value);
          }
        }
      }
    },
    responsive: true,
    elements: {
      line: {
        borderWidth: 2
      }
    },
    pointRadius: 1,
    pointHitRadius: 200,
    plugins: {
      filler: {
        propagate: false
      },
      interaction: {
        intersect: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.dataset.label + " " + tooltipItem.formattedValue + " " + "$/kg";
          }
        }
      },
      legend: { display: true, },
    },
  };
  return (
    <>
      <div className="card-chart my-3 d-flex flex-wrap main-chart  " style={{
        backgroundSize: "auto",
        backgroundImage: "url(" + './assets/img/olma.png' + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}>
        <p></p>
        <Line data={data} options={lineOptions} redraw={true} />
        <div className="card-info ">
          <p className="card-title">Maxsulot: {product}</p>
        </div>
      </div>
    </>
  );
}

export default ChartCardUZB;
