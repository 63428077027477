import React, { Component } from "react";
import StatisticsCardBig from "../components/NewsCard/StatisticsCardBig";
import { getStatistics } from "../service/api";
import Pagination from "react-js-pagination";
class StatisticsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: -1,
      articlesStatics: [],
      title: "",
      tagsString: "",
      tags: [],
      page: 1,
      limit: 10,
      isLoading: false,
    };
  }

  getStatistics(page, limit) {
    let { articlesStatics: articles } = this.state;
    this.setState({ isLoading: true });
    getStatistics(
      page,
      limit,
      (res) => {
        if (res.count !== articles.length) {
          this.setState({
            page: page++,
          });
        }
        this.setState({
          count: res.count,
          articlesAnalysis: res.items,
          isLoading: false,
        });
      },
      (err) => {
        this.setState({
          articles: [],
          isLoading: false,
        });
      }
    );
  }
  PaginatedItems() {
    this.setState({
      pageCount: Math.ceil(this.state.count / this.state.page),
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    this.getStatistics(pageNumber, this.state.limit)
  }
  componentDidMount() {
    this.getStatistics(this.state.page, this.state.limit);
  }

  render() {
    return (
      <div className="container h-center">

        <div className="note note-info text-success bg-white mb-3 shadow-3">
          <h3><strong>QO'LLANMALAR</strong></h3>
        </div>
        <div style={{ padding: "16px 0" }}></div>
        {Array.isArray(this.state.articlesAnalysis) && this.state.articlesAnalysis.length > 0 ? (
          this.state.articlesAnalysis.map((article) => {
            if(article.isManual === 1){
              return <StatisticsCardBig article={article} />;
            }
            return null
          })
        ) : (
          <></>
        )}

        <div style={{ justifyContent: "center", display: "flex" }}>
          <Pagination
            activePage={this.state.page}
            itemsCountPerPage={this.state.limit}
            totalItemsCount={this.state.count / this.state.limit * 1}
            pageRangeDisplayed={10}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default StatisticsList;
