import React from "react";
import { Link } from "react-router-dom";
import { createReadableDate } from "../../utils";

function PrejectNewsCardBig(props) {
  const { article } = props;
  if (!article) {
    return null;
  }
  const {
    id,
    preview_image,
    title,
    subtitle,
    created_at,
  } = article;

  const imagePreview = preview_image?.includes("http")
    ? preview_image
    : "./assets/icons/logo.svg";
  return (
    <Link
      to={`/project-news/${id}`}
      className={`company-card my-3 px-3 py-2`}
      style={{ color: "inherit" }}
    >
      <div className="row">
        <div
          className="col-sm-12 col-md-3 d-flex center"
        >
          <img
            className="company-image"
            src={imagePreview}
            alt={title}
          />
        </div>

        <div className="col-sm-12 col-md-9">
          <div className=" flex-column">
            <br />
            <span className="description-label">
              <img src="./assets/img/calendar.svg" alt="" />{" "}
              {createReadableDate(created_at)}
            </span>
            <br />
            <h2 className="company-name">
              {title}
            </h2>
            <p>
              {subtitle}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default PrejectNewsCardBig;
