import React, { Component } from "react";
import { getVideo } from "../service/api";
import Sanitized from "../components/sanitized/sanitized";
class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      image: '',
      html: '',
    };
  }

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages
    });
  };
  async getVideo(id) {
    getVideo(
      id,
      (res) => {
        var tempUrl = res.link.split("=");
        var embeddedUrl = "https://www.youtube.com/embed/" + tempUrl[1];
        this.setState({
          id: res.id,
          name: res.name,
          image: res.image,
          html: res.html,
          link: embeddedUrl
        });
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.getVideo(id);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      const {
        match: {
          params: { id },
        },
      } = this.props;
      this.getVideo(id);
    }
  }

  render() {
    const isFile = this.state.html;
    return (
      <div className="row">
        <div className="col-sm-12 col-md-12" >
          {
            isFile ? <div id="article-html" style={{ padding: "5px" }}>
              <h1
                style={{ alignItems: 'center', textAlign: "center" }}
                className="article-title-video px-3 py-4"
              >
                {this.state.name}
              </h1>
              <iframe src={this.state.link} className="frame" allowfullscreen title={this.state.name}></iframe>
              <div style={{ marginTop: 20 }}>
                <Sanitized html={this.state.html} />
              </div>
            </div> : <></>
          }
        </div>
      </div>
    );
  }
}

export default Video;
