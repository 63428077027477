import React from "react";
function UserCard(props) {
  const { user } = props;
  if (!user) {
    return null;
  }
  const { name, image, field_of_activity } = user;
  const imagePreview = image?.includes("http")
    ? image
    : "https://dummyimage.com/480/ced4da/6c757d.jpg";
  return (
    <div
      className="card my-3 flex-column mx-1"
      style={{ height: "350px", width: "250px" }}
    >
      <div>
        <img
          src={imagePreview}
          alt={name}
          style={{ height: "210px", objectFit: "cover" }}
          loading="lazy"
        />
      </div>
      <div className="card-info mt-2 p-3">
        <h3 className="card-title">{name}</h3>
        <span
          className="card-stats"
          style={{
            overflow: "hidden",
            display: "inline-block",
          }}
        >
          {field_of_activity}
        </span>
      </div>
    </div>
  );
}

export default UserCard;