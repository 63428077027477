import React, { Component } from "react";
import axios from "axios";
import Loader from "../components/Loader/Loader";
import ReactPdf from "../components/react-pdf/react-pdf";
class OneContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
    };
  }

  getContentId(id) {
    axios.get(
      `${process.env.REACT_APP_BASEURL}/api/content/${id}`).then((res) => {
        this.setState({
          content: res.data.pdf
        })
      })
  }

  componentDidMount() {
    this.getContentId(this.props.match.params.id)
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.getContentId(this.props.match.params.id)
    }
  }

  render() {
    const isFile = this.state.content;
    return (
      <div className="row">
        <div className="col-sm-12 col-md-12" >
          {isFile
            ?
            <div style={{ width: "100%", margin: "10px" }}>
              <ReactPdf props={this.state.content} />
            </div>
            : <Loader></Loader>
          }
        </div>
      </div>
    );
  }
}
export default OneContent;
