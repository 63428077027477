import React from "react";
import sanitize from "sanitize-html";

export default ({ html = "" }) => {


    const sanitized = sanitize(html, {
        allowedTags: ['h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
            'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
            'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre'],
        allowedAttributes: {
            '*': ['href', 'align', 'alt', 'center', 'bgcolor'],
            'iframe': ['src']
        },
        allowedIframeHostnames: ['www.youtube.com'],
        // Lots of these won't come up by default because we don't allow them
        selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
        // URL schemes we permit
        allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
        allowIframeRelativeUrls: true,
        allowedSchemesByTag: {}
    });
    return <span dangerouslySetInnerHTML={{ __html: sanitized }} />;
};
