const photos=[
    {
        id: 1,
        url: "https://shirinmeva.s3.amazonaws.com/1691475484568photo_2023-08-08_10-34-13.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 2,
        url: "https://shirinmeva.s3.amazonaws.com/1691475479516photo_2023-08-08_11-10-38.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 3,
        url: "https://shirinmeva.s3.amazonaws.com/1691475474492photo_2023-08-08_11-10-44.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 4,
        url: "https://shirinmeva.s3.amazonaws.com/1691475467153photo_2023-08-08_11-10-47.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 5,
        url: "https://shirinmeva.s3.amazonaws.com/1691475460577photo_2023-08-08_11-11-03.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 6,
        url: "https://shirinmeva.s3.amazonaws.com/1691475454933photo_2023-08-08_11-11-16.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 7,
        url: "https://shirinmeva.s3.amazonaws.com/1691475448281photo_2023-08-08_11-12-18.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 8,
        url: "https://shirinmeva.s3.amazonaws.com/1691475443098photo_2023-08-08_11-12-29.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 9,
        url: "https://shirinmeva.s3.amazonaws.com/1691475437738photo_2023-08-08_11-12-53.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 10,
        url: "https://shirinmeva.s3.amazonaws.com/1691476196251photo_2023-08-08_11-29-31.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 11,
        url: "https://shirinmeva.s3.amazonaws.com/1664980881409photo_5325700215602265664_y.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 12,
        url: "https://shirinmeva.s3.amazonaws.com/1665072740871photo_5325978246015204756_y%20%281%29.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 13,
        url: "https://shirinmeva.s3.amazonaws.com/1668664398491photo_2022-11-17_10-46-57.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 14,
        url: "https://shirinmeva.s3.amazonaws.com/1663569117468msg-697761521-282041.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 15,
        url: "https://shirinmeva.s3.amazonaws.com/1664966287264photo_5327877300164869691_x.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 16,
        url: "https://shirinmeva.s3.amazonaws.com/1665073503580photo_5325700215602265321_y.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 17,
        url: "https://shirinmeva.s3.amazonaws.com/1669726491167Screen%20Shot%202022-10-03%20at%2010.40.57.png",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 18,
        url: "https://shirinmeva.s3.amazonaws.com/1667883666178photo_2022-11-08_09-11-08.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 19,
        url: "https://shirinmeva.s3.amazonaws.com/1663602415773photo_5407019219419251161_y.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 20,
        url: "https://shirinmeva.s3.amazonaws.com/1669891459521photo_2022-12-01_15-44-05.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 21,
        url: "https://shirinmeva.s3.amazonaws.com/1669011892094photo_2022-11-21_11-17-10.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 22,
        url: "https://shirinmeva.s3.amazonaws.com/1664267372537%D0%91%D0%B5%D0%B7%20%D0%B8%D0%BC%D0%B5%D0%BD%D0%B8-1.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 23,
        url: "https://shirinmeva.s3.amazonaws.com/1663574957107msg-697761521-282084.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 24,
        url: "https://shirinmeva.s3.amazonaws.com/1661415066692chexiya.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 25,
        url: "https://shirinmeva.s3.amazonaws.com/1661343642087agrotv.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 26,
        url: "https://shirinmeva.s3.amazonaws.com/16589267850211-min%281%29.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 27,
        url: "https://shirinmeva.s3.amazonaws.com/16559861476001-min%282%29.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 28,
        url: "https://shirinmeva.s3.amazonaws.com/1649933946639ann.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    },
    {
        id: 29,
        url: "https://shirinmeva.s3.amazonaws.com/1664181239567photo_5300741258702077541_y.jpg",
        name: "ShirinMeva",
        section: "Yangiliklar"
    }
];

export default photos;