import React from 'react';
import {Container} from "@mui/material";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import photos from "./photos";

const Gallery=()=>{
    return(
        <div>
            <Container>
                <SimpleReactLightbox>
                    <SRLWrapper>
                        <ImageList sx={{transform: 'translateZ(0)'}} variant="masonry" cols={3} gap={1}>
                            {photos.map((img)=> {

                                return(
                                    <ImageListItem key={img.url} cols={img.cols || 1} rows={img.rows || 1}>
                                        <img src={`${img.url}?w=248&fit=crop&auto=format`}
                                             srcSet={`${img.url}?w=248&fit=crop&auto=format&dpr=2 2x`} alt={img.name}
                                             loading="lazy"/>
                                        <ImageListItemBar
                                            sx={{
                                                background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                            }}
                                            title={img.name}
                                            subtitle={img.section}
                                            actionIcon={
                                                <IconButton sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                                                            aria-label={`info about ${img.name}`}>
                                                </IconButton>
                                            }
                                        />
                                    </ImageListItem>
                                );
                            })}
                        </ImageList>
                    </SRLWrapper>
                </SimpleReactLightbox>
            </Container>
        </div>)
};

export default Gallery;