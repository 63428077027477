import React, { Component } from "react";
import LegalCardBig from "../components/NewsCard/LegalCardBig";
import { getArticles } from "../service/api";
import InfiniteScroll from "../components/InfiniteScroll/InfiniteScroll";

class LegalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articlesCount: -1,
      articles: [],
      articleType: 'legal',
      prevArticleType: 'legal',
      title: "",
      tagsString: "",
      tags: [],
      page: 1,
      isLoading: false,
    };
  }

  getArticles(typeOfArticle) {
    this.state.title = ''
    let { page, articles, articleType, prevArticleType } = this.state;
    this.setState({ isLoading: true });
    getArticles(
      page,
      20,
      typeOfArticle,
      (res) => {
        if (res.count !== articles.length) {
          this.setState({
            page: page++,
          });
        }
        if (articleType !== prevArticleType) {
          this.setState({
            articlesCount: res.count,
            articles: res.items,
            isLoading: false,
          });
        } else {
          this.setState({
            articlesCount: res.count,
            articles: page !== 1 ? [...res.items, ...articles] : res.items,
            isLoading: false,
          });
        }
      },
      (err) => {
        this.setState({
          articles: [],
          isLoading: false,
        });
      }
    );
  }

  handleSelect(e) {
    this.setState({
      activity: e.target.value,
    });
  }

  handleProducts(e) {
    const tags = e.target.value;
    const tagsArr = tags.split(",");
    this.setState({
      tags: tagsArr,
      tagsString: tags,
    });
  }
  handleSearch(e) {
    const value = e.target.value;
    this.setState({
      title: value,
    });
  }

  componentDidMount() {
    const { articleType } = this.state;
    this.getArticles(articleType);
  }
  render() {
    const {
      articles,
      articleType,
      isLoading,
      articlesCount,
    } = this.state;
    return (
      <div className="container h-center">
        <img className="article-img"
          src="./assets/img/TADBIRLAR.jpg"
          alt="TADBIRLAR"
        />
        <div style={{ padding: "16px 0" }}></div>
        <InfiniteScroll
          onBottomHit={() => this.getArticles(articleType)}
          isLoading={isLoading}
          hasMoreData={articlesCount === -1 && articlesCount !== articles.length}
          loadOnMount={true}
        >
          {Array.isArray(articles) && articles.length > 0 ? (
            articles.map((company) => {
              return <LegalCardBig article={company} />;
            })
          ) : (
            <></>
          )}
        </InfiniteScroll>
      </div>
    );
  }
}

export default LegalList;
