import React, {Component} from "react";
import { getCompany } from "../service/api";

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = props.history.location.state
  }

  async getCompany(id) {
    getCompany(
      id,
      (res) => {
        this.setState({ company: res });
      },
      (err) => {
        // console.log(err);
      }
    );
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }
  componentDidMount(prevProps, prevState) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.getCompany(id);
  }

  render() {
    const {
      name,
      contact_person,
      phone_number,
      mail_web,
      location,
      address,
      eksport,
      image,
    } = this.state;
    this.state.image = this.state.image ? this.state.image : "/assets/img/no_company_preview.png"
    return (
      <div
        className="container "
        style={{ marginTop: 60, marginBottom: 60 }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-3 flex center">
            <img
              className="company-image"
              src={image}
              alt={name}
              width="100%"
            />
          </div>

          <div className="col-sm-12 col-md-9">
            <div className="flex column">
              <h4 className="company-name"><span style={{ fontWeight: "bold", paddingRight: "10px" }}>Kompaniya nomi:  </span>  {name}</h4>
              <h4 className="company-name">
                <span style={{ fontWeight: "bold", paddingRight: "10px" }}> Aloqa qiluvchi shaxs: </span>
                {contact_person}
              </h4>
              <h4 className="company-name">
                {phone_number !== '-' ? (
                  <>
                    <span style={{ fontWeight: "bold", paddingRight: "10px" }}>  Telefon raqami: </span> +998
                    {' ' + phone_number}
                  </>
                ) : (
                  <>
                    <span style={{ fontWeight: "bold", paddingRight: "10px" }}>  Telefon raqami: </span> -
                  </>
                )}
              </h4>
              <h4 className="company-name">
                <span style={{ fontWeight: "bold", paddingRight: "10px" }}>  Email yoki website : </span>
                {mail_web}
              </h4>
              <h4 className="company-name">
                <span style={{ fontWeight: "bold", paddingRight: "10px" }}>Kompaniya  eksportyorlari: </span>
                {eksport}
              </h4>
              <h4 className="company-name">
                <span style={{ fontWeight: "bold", paddingRight: "10px" }}>  Joylashgan joyi: </span>
                {location}
              </h4>
              <h4 className="company-name">
                <span style={{ fontWeight: "bold", paddingRight: "10px" }}>  Manzil: </span>
                {address}
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Company;
