import "./App.css";
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";
import React, { Component } from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import Article from "./pages/Article";
import About from "./pages/About";
import Anor from "./pages/Anor";
import Charts from "./pages/Charts";
import Chart from "./pages/Chart";
import Contacts from "./pages/Contacts";
import Companies from "./pages/Companies";
import ArticleList from "./pages/ArticleList";
import AnalysisList from "./pages/AnalysisList";
import Analysis from "./pages/Analysis";
import StatisticsList from "./pages/StatisticsList";
import ManualsList from "./pages/ManualsList";
import Statistics from "./pages/Statistics";
import PresentationList from "./pages/PresentationList";
import Presentation from "./pages/Presentation";
import EventList from "./pages/EventList";
import Event from "./pages/Event";
import PemegranateList from "./pages/PomegranateList";
import Pomegranate from "./pages/Pomegranate";
import LegalList from "./pages/LegalList";
import Legal from "./pages/Legal";
import FruitsList from "./pages/FruitsList";
import Fruit from "./pages/Fruit";
import ProjectsNewsList from "./pages/ProjectsNewsList";
import Projectnews from "./pages/Projectnews";
import Сompany from "./pages/Company";
import MagazineList from "./pages/MagazineList";
import AdsList from "./pages/AdsList";
import Magazine from "./pages/Magazin";
import VideoList from "./pages/VideoList";
import Video from "./pages/Video";
import ContentList from "./pages/ContentList";
import Content from "./pages/Content";
import OneContent from "./pages/OneContent";
import Maps from "./pages/Maps";
import Map from "./pages/Map";
import OldAnorFestival from "./pages/old.AnorFestival";
import { YMInitializer } from "react-yandex-metrika";
import Gallery from "./pages/Gallery";
class App extends Component {
    constructor(props) {
        super(props);
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.ym(90993948, "hit", window.location.href);
        }
    }

    render() {
        return (<
            >
            <
                YMInitializer accounts={
                    [90993948]}
                options={
                    { defer: true }}
            /> <
                BrowserRouter >
                <
                    Header />
                <
                    main class="container"
                    style={
                        { minHeight: "100vh" }
                    } >
                    <
                        div class="row" >
                        <
                            div class="col-12 col-md-12" >
                            <
                                Switch >
                                <
                                    Route exact path="/"
                                    component={Home}
                                /> <
                                    Route path="/about"
                                    component={About}
                                />  <
                                    Route path="/anor"
                                    component={Anor}
                                /> <
                                    Route path="/charts"
                                    component={Charts}
                                />
                                <
                                    Route path="/maps"
                                    component={Maps}
                                />
                                <
                                    Route path="/festival"
                                    component={OldAnorFestival}
                                />
                                <
                                    Route path="/map/:id"
                                    component={Map}
                                /> <
                                    Route path="/chart/:id"
                                    component={Chart}
                                /> <
                                    Route path="/blog"
                                    component={ArticleList}
                                /> <
                                    Route path="/analysis"
                                    component={AnalysisList}
                                /> <
                                    Route path="/analys/:id"
                                    component={Analysis}
                                /> <
                                    Route path="/statistics"
                                    component={StatisticsList}
                                /> <
                                    Route path="/manuals"
                                    component={ManualsList}
                                /> <
                                    Route path="/statistic/:id"
                                    component={Statistics}
                                /> <
                                    Route path="/presentations"
                                    component={PresentationList}
                                /> <
                                    Route path="/presentation/:id"
                                    component={Presentation}
                                /> <
                                    Route path="/events"
                                    component={EventList}
                                /> <
                                    Route path="/event/:id"
                                    component={Event}
                                />
                                <
                                    Route path="/pomegranates-news"
                                    component={PemegranateList}
                                /> <
                                    Route path="/pomegranates-new/:id"
                                    component={Pomegranate}
                                />
                                <
                                    Route path="/article/:id"
                                    component={Article}
                                /> <
                                    Route path="/contacts"
                                    component={Contacts}
                                /> <
                                    Route path="/companies"
                                    component={Companies}
                                /> <
                                    Route path="/company/:id"
                                    component={Сompany}
                                /> <
                                    Route path="/magazines"
                                    component={MagazineList}
                                /> <
                                    Route path="/ads"
                                    component={AdsList}
                                /> <
                                    Route path="/magazine/:id"
                                    component={Magazine}
                                /> <
                                    Route path="/contents"
                                    component={ContentList}
                                /> <
                                    Route path="/content/:id"
                                    component={Content}
                                /> <
                                    Route path="/contentone/:id"
                                    component={OneContent}
                                /> <
                                    Route path="/videos"
                                    component={VideoList}
                                /> <
                                    Route path="/video/:id"
                                    component={Video}
                                />

                                <
                                    Route path="/fruit-vegetable"
                                    component={FruitsList}
                                /> <
                                    Route path="/fruit-vegetable/:id"
                                    component={Fruit}
                                />
                                <
                                    Route path="/project-news"
                                    component={ProjectsNewsList}
                                /> <
                                    Route path="/project-news/:id"
                                    component={Projectnews}
                                />
                                <
                                    Route path="/legals"
                                    component={LegalList}
                                /> <
                                    Route path="/legal/:id"
                                    component={Legal}
                                />
                                <
                                    Route path="/gallery"
                                          component={Gallery}
                                />
                            </Switch >
                        </div>
                    </div >
                </main>
                < Footer />
            </BrowserRouter> </>
        );
    }
}
export default withRouter(App);