import React, { Component } from "react";
import { Line } from 'react-chartjs-2';
import { getchart } from "../service/api";
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: '',
      country_market: '',
      country_money: '',
      volume: '',
      product: "",
      product_from: "",
      product_group: "",
      product_sell_type: "",
      count: '',
      dates: [],
      prices: [],
      prices_dollar: [],
      prices_local: [],
      prices_local_discount: [],
      charts: [],
      page: 1,
      limit: 10,
      dataDollar: {
        labels: ['Jan', 'Feb'],
        datasets: [
          {
            label: 'My First dataseasdasdasdsat',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [1, 5, 10, 20],
          }
        ]
      },
      dataLocal: {
        labels: ['Jan', 'Feb'],
        datasets: [
          {
            label: 'My First dataseasdasdasdsat',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [1, 5, 10, 20],
          }
        ]
      },
      dataDiscount: {
        labels: ['Jan', 'Feb'],
        datasets: [
          {
            label: 'My First dataseasdasdasdsat',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [1, 5, 10, 20],
          }
        ]
      }
    };
  }
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.getchart(id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const {
        match: {
          params: { id },
        },
      } = this.props;
      this.getchart(id);
    }
  }
  getchart(id) {
    let tempDate = [];
    let tempPrices = [];
    let tempPricesLocal = [];
    let tempPricesDiscount = [];
    getchart(
      id,
      (res) => {
        this.setState({
          prices: res.prices,
          color: res.color,
          country_market: res.country_market,
          country_money: res.country_money,
          volume: res.volume,
          product: res.product,
          product_from: res.product_from,
          product_group: res.product_group,
          product_sell_type: res.product_sell_type,
        })
        this.state.prices.map(x => {
          tempDate.push(new Date(x.date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }))
          tempPrices.push(x.price_dollar)
          tempPricesLocal.push(x.local_price)
          tempPricesDiscount.push(x.local_price_discount)
        })

        this.setState({
          count: res.count,
          dates: tempDate,
          prices_dollar: tempPrices,
          prices_local: tempPricesLocal,
          prices_local_discount: tempPricesDiscount,
        });
        this.pushActionDollar();
        this.pushActionLocal();
        this.pushActionDiscount();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  pushActionDollar = () => {
    const dataState = [0, ...this.state.dates];
    let newdata = {
      label: this.state.country_market,
      fill: false,
      lineTension: 0.1,
      backgroundColor: this.state.color,
      borderColor: this.state.color,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: 'rgba(75,192,192,1)',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [0, ...this.state.prices_dollar],
    }
    this.setState({ dataDollar: { labels: dataState, datasets: [newdata] } });
  }
  pushActionLocal = () => {
    const dataState = [0, ...this.state.dates];
    let newdata = {
      label: this.state.country_market,
      fill: false,
      lineTension: 0.1,
      backgroundColor: this.state.color,
      borderColor: this.state.color,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: 'rgba(75,192,192,1)',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [0, ...this.state.prices_local],
    }
    this.setState({ dataLocal: { labels: dataState, datasets: [newdata] } });
  }
  pushActionDiscount = () => {
    const dataState = [0, ...this.state.dates];
    let newdata = {
      label: this.state.country_market,
      fill: false,
      lineTension: 0.1,
      backgroundColor: this.state.color,
      borderColor: this.state.color,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: 'rgba(75,192,192,1)',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [0, ...this.state.prices_local_discount],
    }
    this.setState({ dataDiscount: { labels: dataState, datasets: [newdata] } });
  }
  styles = {
    fontFamily: 'sans-serif',
    textAlign: 'center',
  };

  lineOptions = {
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          userCallback(value) {
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join('.');
            return `Rp.${value}`;
          },
        },
      }],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  };

  render() {
    return (
      <div style={{ marginTop: 30, marginBottom: 60 }}>

        <div className="d-flex flex-wrap">
          <h4 style={{ padding: "5px" }}> Bozor:    {this.state.country_market} </h4>
          <h4 style={{ padding: "5px" }}> Pul birligi :    {this.state.country_money} </h4>
          <h4 style={{ padding: "5px" }}> Maxsulot :  {this.state.product} </h4>
          <h4 style={{ padding: "5px" }}> Maxsulot gruppasi  :     {this.state.product_group} </h4>
          <h4 style={{ padding: "5px" }}>Olib kelingan davlat :    {this.state.product_from} </h4>
          <h4 style={{ padding: "5px" }}> {this.state.product_sell_type} :  {this.state.volume}  </h4>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginBottom: "20px" }}>
            <h3 style={{ textAlign: "center" }}>Dollardagi narxi bo'yicha  </h3>
            <Line data={this.state.dataDollar} options={this.lineOptions} />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p style={{ textAlign: "center" }}>Mahalliy pul bo'yicha narxi  </p>
            <Line data={this.state.dataLocal} options={this.lineOptions} />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p style={{ textAlign: "center" }}>Mahalliy pul  bo'yicha  chegirma narxi</p>
            <Line data={this.state.dataDiscount} options={this.lineOptions} />
          </div>
        </div>
      </div>
    );
  }
};
export default Chart;