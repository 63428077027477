import React, { Component } from "react";
import { getVideos, getVideosSectionList, getVideoSection } from "../service/api";
import VideoCard from "../components/VideoCard/VideoCard";

class VideoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      section: null,
      sections: [],
      sectionList: [],
      selectedName: '',
      vedios: []
    };
  }

  async getVideos() {
    this.setState({ isLoading: true });
    getVideos(
      (res) => {
        this.setState({
          section: res,
          isLoading: false,
        });
      },
      (err) => {
        this.setState({
          section: [],
          isLoading: false,
        });
      }
    );
  }

  async getVedioSectionList() {
    getVideosSectionList(
      (res) => {
        console.log(res.items)
        this.setState({
          sectionList: res.items,
          isLoading: false,
        });
        this.Section('all')
      },
      (err) => {
        this.setState({
          sectionList: [],
          isLoading: false,
        });
      }
    );
  }
  Section = (name) => {
    this.setState({
      selectedName: name
    })
    if (this.state.selectedName === 'all') {
      getVideoSection(this.state.sectionList[0].name,
        (res) => {
          this.setState({
            section: res.items,
            isLoading: false,
          });
        },
        (err) => {
          this.setState({
            section: [],
            isLoading: false,
          });
        }
      );
    } else {
      getVideoSection(this.state.selectedName,
        (res) => {
          this.setState({
            section: res.items,
            isLoading: false,
          });
        },
        (err) => {
          this.setState({
            section: [],
            isLoading: false,
          });
        }
      );
    }


  }

  componentDidMount() {
    this.getVideos();
    this.getVedioSectionList();
  }
  render() {
    return (
      <div className="card-list row">
        {/* {Array.isArray(this.state.section) && this.state.section.length > 0 ? (
          this.state.section.map((sec, index) => {
            return (
              <><h4 style={{ fontWeight: "bold", marginBottom: "20px", }}>{sec.section} </h4>
                {
                  JSON.parse(sec.list).map((vedio) => {
                    return (
                      <div key={vedio.id} className="col-12 col-sm-6 col-md-3 center" style={{ marginBottom: "30px" }}>
                        <VideoCard video={vedio} />
                      </div>
                    )
                  })}
              </>
            );
          })
        ) : (
          <></>
        )} */}
        <div className="col-md-3 col-lg-3 col-3">
          <ul class="list-group">
            {Array.isArray(this.state.sectionList) && this.state.sectionList.length > 0 ? (
              this.state.sectionList.map((sec) => {
                return (
                  <>
                    <li class="list-group-item " className={this.state.selectedName === sec.name ? ' list-group-item  active' : 'list-group-item '} onClick={() => this.Section(sec.name)} style={{ fontSize: "12px", cursor: "pointer" }}>{sec.name} </li>
                  </>
                );
              })
            ) : (
              <></>
            )}
          </ul>
        </div>
        <div className="col-md-9 col-lg-9 col-9">
          <div className="row">
            {Array.isArray(this.state.section) && this.state.section.length > 0 ? (
              this.state.section.map((sec, index) => {
                return (
                  <div key={sec.id} className="col-12 col-sm-6 col-md-4 center" style={{ marginBottom: "30px" }}>
                    <VideoCard video={sec} />
                  </div>
                )
              })
            ) : (
              <></>
            )}
          </div>

        </div>
      </div>
    );
  }
}

export default VideoList;