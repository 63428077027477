import React from "react";
class SidebarContentLeft extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="col-sm-12 col-md-3 content-text right-content screen-content" style={{ top: "90px", backgroundImage: "url(" + "../assets/img/kolonka.jpg" + ")", backgroundSize:"175%",  backgroundRepeat: "repeat-y", borderRadius: "30px", padding: "20px" ,marginBottom:"30px" }} >
        <p >Muassis: «Annexure Financial Solutions UZB» mas’uliyati cheklangan jamiyati</p>
        <img
          src="../assets/icons/logo-w-text.svg"
          className="conten-logo-img"
        />
        <p style={{ textAlign: "center", fontWeight: "bold" }}>{this.props.name}</p>
        <hr style={{ color: "red", height: "3px" }} />
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>Tahrir hay’ati: </p>
        <p>Aziz VOITOV</p>
        <p>Shavkat HAMRAYEV</p>
        <p>Aktam HAYITOV</p>
        <p>Bahodir TOJIYEV</p>
        <p>Alisher SHUKUROV</p>
        <p>Baxtiyor KAMALOV</p>
        <p>Azamat GULYAMOV</p>
        <p>Dilshod NAZIROV</p>
        <p>Firdavs OLIMOV</p>
        <p>Abdumalik RUSTAMOV</p>
        <p>Sherzod RAJAMETOV</p>
        <p>Shuxrat ABROROV</p>
        <p>Rafik XAKIMOV</p>
        <p>Maxfurat AMANOVA</p>
        <p>Ravza MAVLYANOVA</p>
        <p>Husan HAYDAROV</p>
        <p>Saodat DO‘SMURATOVA</p>
        <p>Baxtiyor SHYERMATOV</p>
        <p>Baxtiyar MATYAKUBOV</p>
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>Bosh muharrir
        : </p>
        <p>Go‘zal PIRJANOVA-SHIRINOVA</p>
        {/* <p style={{ fontWeight: "bold",marginTop: "10px" }}>Фотографлар: </p>
        <p>Abdumalik RUSTAMOV</p>
        <p>Husan HAYDAROV</p>
        <p>Rezida ERDMАN</p> */}
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>Fotograflar: </p>
        <p>Po‘lat YULDASHYEV </p>
        <p>Ulug‘bek HOSHIMOV</p>
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>Dizaynerlar: </p>
        <p>Isroiljon TURGʻUNOV</p>
        <p>Mavluda SAIDXODJAYEVA</p>
          {/* <p style={{ fontWeight: "bold", marginTop: "10px" }}>Tarjimonlar: </p>
          <p>Shuhrat YUNUSOV</p>
          <p>Saida ALIMOVA</p> */}
        {/* <p style={{ fontWeight: "bold", marginTop: "10px" }}>Veb dizayner: </p>
        <p>Elyor MIRSAIDOV</p> */}
        <hr style={{ color: "red", height: "3px" }} />
        <p style={{ fontWeight: "bold" }}>Manzil: 100000,  </p>
        <p>O‘zbekiston Respublikasi,
          Toshkent shahri,
          Mirzo Ulug‘bek tumani,
          Niyozbek yo‘li ko‘chasi, 30-uy,
          5-qavat. </p>
        <p style={{ fontWeight: "bold" }}>Telefon: +998 71 202-11-21 </p>
        <hr style={{ color: "red", height: "3px" }} />
        <p>
          {/* Mualliflar fikri tahririyat nuqtayi
          nazaridan farq qilishi mumkin.
          Ko‘chirib bosilganda nashr nomi
          ko‘rsatilishi shart. */}
          <br />
          Nashr O‘zbekiston Respublikasi
          Prezidenti Administrasiyasi
          huzuridagi Axborot va ommaviy
          kommunikasiyalar agentligi
          tomonidan 2021yil 5-aprelda
          1169 raqami bilan ro‘yxatga
          olingan.
        </p>
      </div>
    );
  }
}

export default SidebarContentLeft;
