import React from "react";
import { getPresentationId } from "../service/api";
import Loader from "../components/Loader/Loader";
class Presentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      pptx: "",
    };
  }
  async getPresentationId(id) {
    getPresentationId(
      id,
      (res) => {
        this.setState({ pptx: res.pptx });
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.getPresentationId(id);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      const {
        match: {
          params: { id },
        },
      } = this.props;
      this.getArticle(id);
    }
  }
  render() {
    const isFile = this.state.pptx;
    return (
      <div className="row">
        <div className="col-12">
          {isFile ? (
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${this.state.pptx}`}
              className="frame-pptx"
              frameBorder="0"
              title="slides"
            ></iframe>
          ) : (
            <Loader></Loader>
          )}
        </div>
      </div>
    );
  }
}

export default Presentation;
