import React, { Component } from "react";
import { getCrops } from "../service/api";

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      region: null,
      name: null,
      sort: null,
      national_country: null,
      register_date: null,
      volume: null,
      weight: null,
      period_harvest: null,
      weight_harvest: null,
      save_date: null,
      main_property: null,
      additional: null,
      eksport:null,
      image: null,
    };
  }

  async getCrops(id) {
    getCrops(
      id,
      (res) => {
        this.setState({
          id: res.id,
          region: res.region,
          name: res.name,
          sort: res.sort,
          national_country: res.national_country,
          register_date: res.register_date,
          volume: res.volume,
          weight: res.weight,
          period_harvest: res.period_harvest,
          weight_harvest: res.weight_harvest,
          save_date: res.save_date,
          main_property: res.main_property,
          additional: res.additional,
          image: res.image,
          eksport:res.eksport
        })
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.getCrops(id);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      const {
        match: {
          params: { id },
        },
      } = this.props;
      this.getCrops(id);
    }
  }

  render() {
    this.state.image = this.state.image ? this.state.image : "/assets/img/fruits.jfif";
    return (
      <div className="row">
        <div className="col-sm-12 col-md-3 flex center">
          <img
            className="company-image"
            src={this.state.image}
            alt={this.state.name}
            width="100%"
          />
        </div>
        <div className="col-sm-12 col-md-9">
          <div className="flex column">
            <h4 className="company-name"><span style={{ fontWeight: "bold", paddingRight: "10px" }}>Имя:  </span>  {this.state.name}</h4>
            <h4 className="company-name">
              <span style={{ fontWeight: "bold", paddingRight: "10px" }}> Тип: </span>
              {this.state.sort}
            </h4>
            <h4 className="company-name">
              <span style={{ fontWeight: "bold", paddingRight: "10px" }}> Назначения сорта: </span>
              {this.state.eksport}
            </h4>
            <h4 className="company-name">
              <span style={{ fontWeight: "bold", paddingRight: "10px" }}>  Государство: </span>
              {this.state.national_country}
            </h4>
            <h4 className="company-name">
              <span style={{ fontWeight: "bold", paddingRight: "10px" }}> Год внесения в госреестр: </span>
              {this.state.register_date}
            </h4>
            <h4 className="company-name">
              <span style={{ fontWeight: "bold", paddingRight: "10px" }}>Тип размера плода: </span>
              {this.state.volume}
            </h4>
            <h4 className="company-name">
              <span style={{ fontWeight: "bold", paddingRight: "10px" }}>  Вес урожая (граммы) </span>
              {this.state.weight}
            </h4>
            <h4 className="company-name">
              <span style={{ fontWeight: "bold", paddingRight: "10px" }}> Срок приготовления (ранний или поздний): </span>
              {this.state.period_harvest}
            </h4>
            <h4 className="company-name">
              <span style={{ fontWeight: "bold", paddingRight: "10px" }}>  Производительность с/г: </span>
              {this.state.weight_harvest}
            </h4>
            <h4 className="company-name">
              <span style={{ fontWeight: "bold", paddingRight: "10px" }}> Срок годности (дней): </span>
              {this.state.save_date}
            </h4>
            <h4 className="company-name">
              <span style={{ fontWeight: "bold", paddingRight: "10px" }}> Различные функции: </span>
              {this.state.main_property}
            </h4>
            <h4 className="company-name">
              <span style={{ fontWeight: "bold", paddingRight: "10px" }}> Дополнительная информация: </span>
              {this.state.additional}
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default Map;
