import React, { Component } from "react";
import { getContenByMagazineId, getMagazine } from "../service/api";
import ContentListCard from '../components/ContentLisCard/ContentList';
import SidebarContentRight from "../components/Sidebar-content/Sidebar-Content-right";
import SidebarContentLeft from "../components/Sidebar-content/Sidebar-Content-left";
class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: '',
      magazine: []
    };
  }

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages
    });
  };

  async getMagazine(id) {
    getMagazine(
      id,
      (res) => {
        this.state.pdf = res;
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  async getContenByMagazineId(id) {
    getContenByMagazineId(
      id,
      (res) => {
        this.setState({
          magazine: res
        });
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  handle = () => {
    window.open(this.state.pdf.file, '_blanker')
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.getContenByMagazineId(id);
    this.getMagazine(id)
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      const {
        match: {
          params: { id },
        },
      } = this.props;
      this.getContenByMagazineId(id);
      this.getMagazine(id)
    }
  }

  render() {
    const oldJurnals=this.state.magazine;
    var first =oldJurnals[0];
    return (
      <div className="row" style={{ border: "2px  solid", borderColor: "lightpink", marginBottom: "20px", padding: "5px", borderRadius: "20px" }}>
        {
          first?.magazine_id<=31 ? <SidebarContentRight name={this.state.pdf.name} /> :<SidebarContentLeft name={this.state.pdf.name} />
        }
        <div className="col-sm-12 col-md-9" >
          <h1 className="content">Mundarija</h1>
          {Array.isArray(this.state.magazine) && this.state.magazine.length > 0 ? (
            this.state.magazine.map((content) => {
              return (
                <ContentListCard content={content} />
              );
            })
          ) : (
            <></>
          )}
        </div>

        <div className="row  " >
          <p style={{ textAlign: "center" }} onClick={this.handle}>
            <a className="pdf-link" >
              Jurnalning to'liq talqinini yuklab olish  <img src="../assets/img/pdf.png" height="20px" alt="" />
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default Content;
