import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
// using ES6 modules
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// using CommonJS modules
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ReactPdf(props) {
  const [numPages, setNumPages] = useState(null);
  const [load, setLoad] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function onDocumentLoadProgress({ loaded, total }) {
    let loading = (loaded / total) * 100;
    if (loading == 100) {
      setLoad(true)
    } else {
      setLoad(false)
    }
  }
  return (
    <div style={{ width: "100%" }}>
      <Document className="custom-class-name-1"
        file={props.props}
        options={{
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
        onLoadProgress={onDocumentLoadProgress}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {
          Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((page) => <Page pageNumber={page} />)
        }
      </Document>
    </div>
  );
}
export default ReactPdf;