import React, { Component } from "react";
import { getMagazines } from "../service/api";
import MagazineCard from "../components/MagazineCard/Magazineard";
class MagazineList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      magazine: [],
      magazineCount: "",
      isLoading: false,
    };
  }

  async getMagazines() {
    this.setState({ isLoading: true });
    getMagazines(
      (res) => {
        this.setState({
          magazine: res.items,
          magazineCount: res.count,
          isLoading: false,
        });
      },
      (err) => {
        this.setState({
          magazine: [],
          isLoading: false,
        });
      }
    );
  }
  componentDidMount() {
    this.getMagazines();
  }
  static getDerivedStateFromProps(props, state) {
    getMagazines()
  }
  render() {
    return (
      <div className="container h-center">
        <div className="row my-5">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src="./assets/img/ads/newrek.jpg" alt="News Banner" className="w-100"/>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src="./assets/img/ads/ad24.jpg" alt="News Banner" className="w-100"/>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src="./assets/img/ads/ad22.jpg" alt="News Banner" className="w-100"/>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src="./assets/img/ads/ad25.jpg" alt="News Banner" className="w-100"/>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src="./assets/img/ads/ad1.jpg" alt="News Banner" className="w-100"/>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src="./assets/img/ads/ad15.jpg" alt="News Banner" className="w-100"/>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src="./assets/img/ads/ad16.jpg" alt="News Banner" className="w-100"/>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src="./assets/img/ads/ad17.jpg" alt="News Banner" className="w-100"/>
          </div>
        </div>
        <div className="row my-5">
        <div className="col-lg-6 col-md-12 col-sm-12">
            <img src="./assets/img/ads/ad23.jpg" alt="News Banner" className="w-100"/>
          </div>
        </div>
        
        
        {/* <img
          className="page-preview-img"
          alt="jurnal"
          src="./assets/img/oblojka.jpg"
        />
        <div style={{ padding: "16px 0" }}></div>
        <div className="card-list row">
          {Array.isArray(this.state.magazine) && this.state.magazine.length > 0 ? (
            this.state.magazine.map((magazine) => {
              return (
                <div key={magazine.id} className="col-12 col-sm-6 col-md-3 center">
                  <MagazineCard magazine={magazine} />
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div> */}
      </div>
    );
  }
}

export default MagazineList;
