import React, { Component } from "react";
const logo2 = window.location.origin + "/assets/img/footer_logo.png";
export default class Footer extends Component {

  render() {
    return (
        <footer className="container-fluid py-3 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-4 d-flex flex-column">

                <a href="/">
                  <img  src={logo2} className="logo-shirinmeva" width="50%" alt="logo"/>
                </a>

                <p className="logo-text font-weight-bold"> Oʼzbekistonda meva-sabzavotchilik marketingi boʼyicha texnik koʼmak loyihasi</p>

                <ul
                    style={{ listStyle: "inside", listStyleType: "disc" }}
                    className="footer-links d-flex flex-column flex-wrap">
                  <li >
                      <span>
                        <a href="https://www.facebook.com/shirinmeva/" target="_blank" rel="noopener" style={{ paddingRight: "10px" }}>
                          <img  alt="facebook" src="/assets/icons/facebook2.svg" width="32px" height="32px" />
                        </a>
                      </span>
                    <span>
                        <a href="https://www.youtube.com/channel/UC1-m-3N6EQ2LJzdmoKK0CEg" target="_blank" rel="noopener" style={{ paddingRight: "10px" }}>
                          <img alt="youtube" src="/assets/icons/movie.svg" width="32px" height="32px" />
                        </a>
                      </span>
                    <a href="https://t.me/shirinmeva" target="_blank" rel="noopener" style={{ paddingRight: "10px" }}>
                      <img alt="shirinmeva" src="/assets/icons/telegram2.svg" width="32px" height="32px" />
                    </a>
                    <span>
                        <a href="https://www.instagram.com/shirinmeva" target="_blank"
                           rel="noopener" style={{paddingRight: "10px"}}>
                          <img alt="instagram" src="/assets/icons/instagram1.svg" width="32px"
                               height="32px"/>
                        </a>
                      </span>
                  </li>
                </ul>
                <p className="font-weight-bold"> ShirinMeva mobil ilovasini yuklab oling:</p>
                <ul className="app-download">
                  <li style={{display: "inline-block"}}>
                    <a href="https://play.google.com/store/apps/details?id=org.uicgrop.shirin_meva" target="_blank"><img src="/assets/img/play11.png" alt="" style={{maxWidth: "150px"}}/></a>
                  </li>
                  <li style={{display: "inline-block"}}>
                    <a href="https://apps.apple.com/uz/app/shirin-meva/id6446432525" target="_blank"><img src="/assets/img/store11.png" alt="" style={{maxWidth: "150px"}}/></a>
                  </li>
                </ul>

              </div>

              <div className="col-12 col-md-4 align-center iframe" >
                <iframe className="map"
                        src="https://yandex.com/map-widget/v1/?um=constructor%3Aaaa7fc45d913a1ab81bc5f66ab0f993e71451e77b3403096e207699501974823&amp;source=constructor"
                        width="100%"/>

              </div>

              <div className="d col-12 col-md-4 ">
                <div className="row text-right">
                  <h4>Biz bilan bog‘laning</h4>
                  <ul
                      style={{ listStyle: "inside", listStyleType: "disc" }}
                      className="footer-links d-flex flex-column flex-wrap "
                  >
                    <li className="align-self-end">
                      O'zbekiston Respublikasi, Toshkent shahri,
                    </li>
                    <li className="align-self-end">Mirzo Ulug'bek tumani, Niyozbek yo’li 30</li>
                    <li className="align-self-end">Annexure Financial Solutions</li>
                    <li style={{ display: "inline-block" }} className="align-self-end"> <i className="fa fa-phone-alt " style={{ paddingRight: "10px" }}></i> <a href="tel:+998712021121">+998 71 202-11-21</a>  </li>
                    <li style={{ display: "inline-block" }} className="align-self-end"> <i className="fas fa-envelope" style={{ paddingRight: "10px" }}></i>
                      <a href="mailto:info@shirinmeva.uz">info@shirinmeva.uz</a>
                    </li>

                  </ul></div>
              </div>
            </div>
          </div>
          <div className="text-center b-0">
          <p className="text-white b-0 m-0"> &copy;
            <script>document.write(new Date().getFullYear());</script>
            Sayt <i className="fa fa-heart-o" aria-hidden="true"></i> <a
                href="https://annexurefs.com" target="_blank"> Annexure</a> tomonidan tayyorlandi
          </p>
          </div>
        </footer>
    );
  }
}