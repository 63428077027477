import React, { Component } from "react";
import axios from "axios";
import InputMask from 'react-input-mask';
class OldAnorFestival extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      address: "",
      companyname: "",
      isanor: false,
      anorvolume: 0,
      isfarmer: false,
      farmervolume: 0,
      ispeople: false,
      peoplevolume: 0,
      iselleranor: false,
      selleranorvolume: 0,
      issuplier: false,
      supliervolume: 0,
      isphd: false,
      phdvolume: 0,
      another: "",
      mass_media: false,
      social_media: false,
      bygoverment: false,
      byfestival: false,
      phone: "",
      telegramphone: "",
      email: "",
      check: false,
    };
  }

  componentDidMount() {
    this.setState({
      check: false
    })
  }

  Register = () => {
    axios.post(`${process.env.REACT_APP_BASEURL}/api/anor/events`, this.state).then(res => {
      this.setState({
        check: true
      })
    })
  }
  onFullNameChange = (event) => {
    this.setState({
      fullname: event.target.value
    });
  }

  onAddressChange = (event) => {
    this.setState({
      address: event.target.value
    });
  }

  onCompanyNameChange = (event) => {
    this.setState({
      companyname: event.target.value
    });
  }

  onIsAnorChange = () => {
    this.setState({
      isanor: !this.state.isanor
    });
  }

  onAnorVolumeChange = (event) => {
    this.setState({
      anorvolume: event.target.value
    });
  }

  onIsFarmerChange = () => {
    this.setState({
      isfarmer: !this.state.isfarmer
    });
  }

  onFarmerVolumeChange = (event) => {
    this.setState({
      farmervolume: event.target.value
    });
  }

  onIspeopleChange = () => {
    this.setState({
      ispeople: !this.state.ispeople
    });
  }

  onPeopleVolumeChange = (event) => {
    this.setState({
      peoplevolume: event.target.value
    });
  }

  onIsSellerAnorChange = () => {
    this.setState({
      iselleranor: !this.state.iselleranor
    });
  }

  onSellerAnorVolumeChange = (event) => {
    this.setState({
      selleranorvolume: event.target.value
    });
  }

  onIsSuplierChange = () => {
    this.setState({
      issuplier: !this.state.issuplier
    });
  }

  onSuplierVolumeChange = (event) => {
    this.setState({
      supliervolume: event.target.value
    });
  }

  onIsphdChange = () => {
    this.setState({
      isphd: !this.state.isphd
    });
  }

  onPhdVolumeChange = (event) => {
    this.setState({
      phdvolume: event.target.value
    });
  }

  onAnotherChange = (event) => {
    this.setState({
      another: event.target.value
    });
  }

  onMassMediaChange = () => {
    this.setState({
      mass_media: !this.state.mass_media
    });
  }

  onSocialMediaChange = () => {
    this.setState({
      social_media: !this.state.social_media
    });
  }

  onByGovernmentChange = () => {
    this.setState({
      bygoverment: !this.state.bygoverment
    });
  }

  onFestivalChange = () => {
    this.setState({
      byfestival: !this.state.byfestival
    });
  }

  onPhoneChange = (event) => {
    this.setState({
      phone: event.target.value
    });
  }

  onTelegramPhoneChange = (event) => {
    this.setState({
      telegramphone: event.target.value,
    });
  }

  onEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  }


  render() {
    return (
      <div className="container" style={{ marginBottom: 20 }}>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="row d-flex justify-content-center">
              {this.state.check
                ? <>
                  <h2 className="d-flex justify-content-center">Tabriklaymiz  siz  ruyxatdan  o'tdiz.  Sizni  festivalga  kutib qolamiz!!</h2>
                </>
                :
                <>
                  <form className="col-lg-6 col-md-6 col-sm-12" >
                    <h2 className="bold">Ro'yxatdan o'tish</h2>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Familiyasi, ismi, sharifi</label>
                      <input type="text" class="form-control" value={this.state.fullname} onChange={this.onFullNameChange} placeholder="Familiyasi, ismi, sharifi" />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Yashash manzil (viloyat, shahar, tuman)</label>
                      <input type="text" class="form-control" value={this.state.address} onChange={this.onAddressChange} placeholder="Yashash manzil (viloyat, shahar, tuman)" />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Korxona nomi</label>
                      <input type="text" class="form-control" value={this.state.companyname} onChange={this.onCompanyNameChange} placeholder="Korxona nomi" />
                    </div>
                    <label htmlFor="" className="pt-3 bold">Faoliyat turi va qancha yer maydoniga ega</label>
                    <div className="d-flex pt-3 ">
                      <div className="col-4">
                        <label for="exampleInputEmail1">Anorchi</label>
                        <input class="form-group ml-2" type="checkbox" defaultChecked={this.state.isanor} value={this.state.isanor} onChange={this.onIsAnorChange} />
                      </div>
                      <div className="col-8 d-flex">
                        <input type="number" class="form-control" style={{ width: '100px' }} value={this.state.anorvolume} onChange={this.onAnorVolumeChange} placeholder="ga/sotix" />
                        <label for="exampleInputEmail1" className="pl-2" style={{ width: '200px' }}>ga/sotix</label>
                      </div>
                    </div>
                    <div className="d-flex pt-3 ">
                      <div className="col-4">
                        <label for="exampleInputEmail1">fermer</label>
                        <input class="form-group ml-2" type="checkbox" id="inlineCheckbox2" value={this.state.isfarmer} onChange={this.onIsFarmerChange} />
                      </div>
                      <div className="col-8 d-flex">
                        <input type="number" class="form-control" style={{ width: '100px' }} value={this.state.farmervolume} onChange={this.onFarmerVolumeChange} placeholder="ga/sotix" />
                        <label for="exampleInputEmail1" className="pl-2" style={{ width: '200px' }}>ga/sotix</label>
                      </div>
                    </div>
                    <div className="d-flex pt-3 ">
                      <div className="col-4">
                        <label for="exampleInputEmail1">Dehqon</label>
                        <input class="form-group ml-2" type="checkbox" id="inlineCheckbox2" value={this.state.ispeople} onChange={this.onIspeopleChange} />
                      </div>
                      <div className="col-8 d-flex">
                        <input type="number" class="form-control" style={{ width: '100px' }} value={this.state.peoplevolume} onChange={this.onPeopleVolumeChange} placeholder="ga/sotix" />

                        <label for="exampleInputEmail1" className="pl-2" style={{ width: '200px' }}>ga/sotix</label>
                      </div>
                    </div>

                    <div className="d-flex pt-3 ">
                      <div className="col-6 d-flex align-items-center">
                        <label for="exampleInputEmail1" style={{ maxWidth: '300px' }}>Anor sotuvchi  va qayta ishlovchi</label>
                        <input class="form-group ml-2" type="checkbox" id="inlineCheckbox2" value={this.state.iselleranor} onChange={this.onIsSellerAnorChange} />
                      </div>
                      <div className="col-6 d-flex">
                        <input type="number" class="form-control ml-3" style={{ height: '40px' }} value={this.state.selleranorvolume} onChange={this.onSellerAnorVolumeChange} placeholder="ga/sotix" />
                        <label for="exampleInputEmail1" className="pl-2" >ga/sotix</label>
                      </div>
                    </div>
                    <div className="d-flex pt-3 ">
                      <div className="col-6 d-flex align-items-center">
                        <label for="exampleInputEmail1" style={{ width: '300px' }}>Anorchilarga mahsulot yetkazib beruvchi</label>
                        <input class="form-group pl-2 " type="checkbox" id="inlineCheckbox2" value={this.state.issuplier} onChange={this.onIsSuplierChange} />
                      </div>
                      <div className="col-6 d-flex">
                        <input type="number" class="form-control ml-3" style={{ height: '40px' }} value={this.state.supliervolume} onChange={this.onSuplierVolumeChange} placeholder="ga/sotix" />
                        <label for="exampleInputEmail1" className="pl-2" >ga/sotix</label>
                      </div>
                    </div>
                    <div className="d-flex pt-3 ">
                      <div className="col-6 d-flex align-items-center">
                        <label for="exampleInputEmail1" style={{ width: '300px' }}>Davlat xizmatchisi, ilm-fan vakili</label>
                        <input class="form-group " type="checkbox" id="inlineCheckbox2" value={this.state.isphd} onChange={this.onIsphdChange} />
                      </div>
                      <div className="col-6 d-flex">
                        <input type="number" class="form-control ml-3" style={{ height: '40px' }} value={this.state.phdvolume} onChange={this.onPhdVolumeChange} placeholder="ga/sotix" />
                        <label for="exampleInputEmail1" className="pl-2" >ga/sotix</label>
                      </div>
                    </div>
                    <div className="d-flex pt-3 ">
                      <div className="col-6 ">
                        <label for="exampleInputEmail1" >Boshqasi  (qaysi faoliyat turi)</label>
                      </div>
                      <div className="col-6 d-flex">
                        <input type="text" class="form-control" value={this.state.another} onChange={this.onAnotherChange} placeholder="" />
                      </div>
                    </div>
                    <label htmlFor="Festival haqida ma'lumotni qaerdan eshitdingiz" className="pt-3 bold">Festival haqida ma'lumotni qaerdan eshitdingiz</label>
                    <div class="form-group d-flex">
                      <label for="exampleInputPassword1" style={{ width: '300px' }}> Rasmiy OAV orqali</label>
                      <input class="form-group " type="checkbox" id="inlineCheckbox2" value={this.state.mass_media} onChange={this.onMassMediaChange} />
                    </div>
                    <div class="form-group d-flex">
                      <label for="exampleInputPassword1" style={{ width: '300px' }}> Ijtimoiy tarmoq orqali</label>
                      <input class="form-group " type="checkbox" id="inlineCheckbox2" value={this.state.social_media} onChange={this.onSocialMediaChange} />
                    </div>
                    <div class="form-group d-flex">
                      <label for="exampleInputPassword1" style={{ width: '300px' }}> Davlat tashkiloti taklifi bilan keldim</label>
                      <input class="form-group " type="checkbox" id="inlineCheckbox2" value={this.state.bygoverment} onChange={this.onByGovernmentChange} />
                    </div>
                    <div class="form-group d-flex">
                      <label for="exampleInputPassword1" style={{ width: '300px' }}> Festival qatnashchisi taklifi bilan keldim</label>
                      <input class="form-group " type="checkbox" id="inlineCheckbox2" value={this.state.byfestival} onChange={this.onFestivalChange} />
                    </div>
                    <label htmlFor="" className="pt-3 bold">Aloqa</label>
                    <div class="form-group">
                      <label for="exampleInputPassword1">Mobil raqam</label>
                      <InputMask class="form-control" value={this.state.phone} placeholder="Qatnashuvchi  raqami kiriting" onChange={this.onPhoneChange} {...this.props} mask="+\9\98  99 999 99 99" maskChar=" " />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputPassword1">Telegram raqami</label>
                      <InputMask class="form-control" value={this.state.telegramphone} placeholder="Qatnashuvchi telegram raqam" onChange={this.onTelegramPhoneChange} {...this.props} mask="+\9\98  99 999 99 99" maskChar=" " />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputPassword1">Email</label>
                      <input type="email" class="form-control" value={this.state.email} placeholder="Email kiriting " onChange={this.onEmailChange} />
                    </div>
                    <div class="form-group pt-3" style={{ cursor: "pointer" }}>
                      <button type="button" class="btn btn-primary" onClick={this.Register}>Ro'yxatdan o'tish</button>
                    </div>
                  </form>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    )

  }
};

export default OldAnorFestival;