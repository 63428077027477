import React from "react";
import { getStatistic } from "../service/api";
import Loader from "../components/Loader/Loader";
import ReactPdf from "../components/react-pdf/react-pdf"
class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: ''
    };
  }
  async getStatistic(id) {
    getStatistic(
      id,
      (res) => {
        this.setState({ file: res.file });
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.getStatistic(id);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      const {
        match: {
          params: { id },
        },
      } = this.props;
      this.getArticle(id);
    }
  }
  render() {
    const isFile = this.state.file;
    return (
      <div className="row">
        <div className="col-12" >
          {isFile
            ?
            <div style={{ width: "100%", margin:"10px" }}>
              <ReactPdf props={this.state.file} />
            </div>
            : <Loader></Loader>
          }
        </div>
      </div>
    );
  }
}

export default Statistics;
