import React, { Component } from "react";

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: null,
      articles: [],
    };
  }

  render() {
    return (
      <>
        <div className="container">
          <img
            className="page-preview-img"
            src="./assets/img/contacts_preview.png"
          />
          <h1> Biz bilan bog‘laning</h1>
          <div className="row" style={{ marginTop: 48 }}>
            <div className="col-sm-12 col-md-6">
              <div className="contact-list">
                <h2 style={{ marginTop: 36 }}>
                  O'zbekiston Respublikasi, Toshkent shahri, Mirzo Ulug'bek
                  tumani, Mustaqilik shoh ko'chasi, 59-uy
                </h2>
                <h2 style={{ marginTop: 36 }}>Annexure Financial Solutions</h2>
                <h2 style={{ marginTop: 36 }}>+99878 120-11-21</h2>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A76032fce706a2a9742197bd2d7e8f2e6e75264478be11252472b6063c57e9cb6&amp;source=constructor"
                width="100%"
                height="360"
              ></iframe>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Contacts;
