import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import Events from "../components/Sidebar/Events";
import TopNews from "../components/Sidebar/TopNews";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import axios from "axios";
import AnorNewsCard from "../components/NewsCard/AnorNewsCard";
import MainCard from "../components/NewsCard/MainCard";
import LeftNewsCard from "../components/NewsCard/LeftNewsCard";
import './home.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const eu = window.location.origin + "/assets/img/eu.png";
const world = window.location.origin + "/assets/img/word.jpeg";
const uz = window.location.origin + "/assets/img/Uzbekistan_Gerb.png";
const iscad = window.location.origin + "/assets/img/iscad.jpg";
const klefman = window.location.origin + "/assets/img/kleffman.png";
const annexure = window.location.origin + "/assets/img/logo_.jpg";

var settings = {
  dots: false,
  lazyLoad: true,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 2,
  initialSlide: 0,
  //adaptiveHeight: true,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 3000,
  cssEase: "linear",

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 3,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 5
      }
    }
  ]
};

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: null,
      articles: [],
      news: [],
      anornews: [],
      pinned: [],
      events: [],
      isMain: [],
      topArticles: [],
      loadingNews: false,
      loadingArticle: false,
    };
  }

  componentDidMount() {
    this.getNews();
    this.getTopArticles();
    this.getPinned();
    this.getEvent();
    this.getAnorNews();
    this.getisMain();
  }
  getArticles() {
    axios.get(`${process.env.REACT_APP_BASEURL}/api/articles/main-page`).then(res => {
      this.setState({
        articles: res.data
      })
    })
  }

  getisMain() {
    axios.get(`${process.env.REACT_APP_BASEURL}/api//main/article`).then(res => {
      this.setState({
        isMain: res.data
      })
    })
  }

  getNews() {
    axios.get(`${process.env.REACT_APP_BASEURL}/api/news/main-page`).then(res => {
      this.setState({
        news: res.data
      })
    })
  }
  getTopArticles() {
    axios.get(`${process.env.REACT_APP_BASEURL}/api/best_articles/main-page`).then(res => {
      this.setState({
        topArticles: res.data
      })
    })
  }
  getPinned() {
    axios.get(`${process.env.REACT_APP_BASEURL}/api/pinned/main-page`).then(res => {
      this.setState({
        pinned: res.data
      })
    })
  }
  getEvent() {
    axios.get(`${process.env.REACT_APP_BASEURL}/api/events/main-page`).then(res => {
      this.setState({
        events: res.data
      })
    })
  }
  getAnorNews() {
    axios.get(`${process.env.REACT_APP_BASEURL}/api/anor/main-page`).then(res => {
   //   console.log(res.data)
      this.setState({
        anornews: res.data
      })
    })
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const {
      news,
      isMain,
      anornews,
      loadingArticle,
      pinned,
      events,
      topArticles,
    } = this.state;
    return (
      <>
        <section id="general-news" className="container" style={{marginTop: "40px"}}>
          <Carousel
            onClickItem={(index, item) => {
              this.props.history.push(`/article/${pinned[index].id}`);
            }}
            autoPlay={true}
            interval={4000}
            infiniteLoop={true}
            showArrows={true}
          >
            {Array.isArray(pinned) && pinned.length > 0 ? (
              pinned.map((pin) => {
                return (
                  <>
                    <div className="large-screen" key={pin.banner_image}>
                      <div
                        className="card card-big row flex-md-row-reverse flex-column align-items-center"
                        style={{ height: "100%" }}
                      >
                        <div className="col-12 col-sm-12 col-md-12">
                          <div className="card-image">
                            <img
                              src={
                                pin.banner_image?.includes("http")
                                  ? pin.banner_image
                                  : "/assets/icons/logo.svg"
                              }
                              className="d-block mx-lg-auto img-fluid"
                              alt=""
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="small-screen" key={pin.preview_image}>
                      <div
                        className="card card-big row flex-md-row-reverse flex-column align-items-center"
                        style={{ height: "100%" }}>
                        <div className="col-10 col-sm-12 col-md-5">
                          <div className="card-image">
                            <img
                              src={
                                pin.preview_image?.includes("http")
                                  ? pin.preview_image
                                  : "/assets/icons/logo.svg"
                              }
                              className="d-block mx-lg-auto img-fluid"
                              alt=""
                              loading="lazy"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <h1 className="mb-3 px-5 pin" style={{ fontSize: "2rem" }}>
                            {pin.title}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <></>
            )}
          </Carousel>
        </section>

        {/* <section id="news" className="container ma-2">
          <div className="container">
            <div className="row">
              <img src="/assets/img/bigad.jpg" alt="" />
            </div>
          </div>
        </section> */}


        <section id="news" className="container ma-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                <div className="page-wrapper">
          <Link to="/blog?article_type=news" style={{color: 'black'}}>
            <h2>Xabarlar</h2>
          </Link>
          <div className="main-menu ">
            <div style={{padding:'5px'}}>
              {
                loadingArticle ? (
                  <Loader />
                ) :
                  Array.isArray(isMain) && isMain.length > 0 ? (
                    isMain.map((article, i) => {
                      return (
                        <div key={i} className="col-12 pa-1">
                          <MainCard key={i} article={article} />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )
              }
            </div>
            <div    style={{padding:'5px'}}>
              {
                loadingArticle ? (
                  <Loader />
                ) :
                  Array.isArray(news) && news.length > 0 ? (
                    news.map((article, i) => {
                      return (
                        <div key={i} className="col-12" style={{padding:'5px'}}> 
                          <LeftNewsCard key={i} article={article} />
                        </div>
                        
                      );
                    })
                  ) : (
                    <></>
                  )
              }
            </div>
          </div>

            </div>
          </div>
              {/*  right sidebar*/}
              <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <div className="sidebar">

                  {/* <div className="widget">
                                        <div className="banner-spot clearfix">
                                            <div className="banner-img">
                                                <img src="/assets/img/banner_07.jpg" alt="" className="img-fluid"/>
                                            </div>
                                        </div>
                                    </div>
                                   */}
                  <div className="widget">
                   <a href="https://www.youtube.com/channel/UC1-m-3N6EQ2LJzdmoKK0CEg/videos" target="_blank" style={{color: 'black'}}>
                    <h2 className="widget-title">Video galereya</h2>
                  </a>
                    <div className="trend-videos">

                      <div className="blog-box">
                        <div className="post-media">
                          <a href="https://www.youtube.com/watch?v=JNTrbJWtPKY" target="_blank" title="">
                            <img src="/assets/img/header.jpg" alt=""
                                 className="img-fluid" loading="lazy"/>
                            <div className="hovereffect">
                              <span className="videohover"></span>
                            </div>
                          </a>
                        </div>
                        <div className="blog-meta">
                          <h4><a href="https://www.youtube.com/watch?v=JNTrbJWtPKY" target="_blank" title="">Anor parvarishidagi muammolarni hal etish yo‘llari</a></h4>
                        </div>
                      </div>

                      <div className="blog-box">
                        <div className="post-media">
                          <a href="https://www.youtube.com/watch?v=JMZbrRzE1lY" target="_blank" title="">
                            <img src="/assets/img/middle.jpg" alt=""
                                 className="img-fluid" loading="lazy"/>
                            <div className="hovereffect">
                              <span className="videohover"></span>
                            </div>
                          </a>
                        </div>

                        <div className="blog-meta">
                          <h4><a href="https://www.youtube.com/watch?v=JMZbrRzE1lY" target="_blank" title="">Germaniya meva-sabzavot bozoriga eksport qilish sirlari</a></h4>
                        </div>
                      </div>

                      <div className="blog-box">
                        <div className="post-media">
                          <a href="https://www.youtube.com/watch?v=LMBiWp8AQzk" target="_blank" title="">
                            <img src="/assets/img/footer.jpg" alt=""
                                 className="img-fluid" loading="lazy"/>
                            <div className="hovereffect">
                              <span className="videohover"></span>
                            </div>
                          </a>
                        </div>
                        <div className="blog-meta">
                          <h4><a href="https://www.youtube.com/watch?v=LMBiWp8AQzk" target="_blank" title="">Ilmiy tadqiqot, yangi kartoshka navlari va yangi urug‘liklar</a></h4>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="anornews" className="container my-5">
          <Link to={`/pomegranates-news`} style={{color: 'black'}}>
            <h2> Anorchilar Assotsiatsiyasi yangiliklari</h2>
          </Link>
          <div className="card-list row">
            {
              loadingArticle ? (
                      <Loader />
                  ) :
                  Array.isArray(anornews) && anornews.length > 0 ? (
                      anornews.map((anor, i) => {
                        return (
                            <div key={i} className="col-12 col-sm-6 col-md-3 d-flex justify-content-evenly ">
                              <AnorNewsCard key={i} article={anor} />
                            </div>
                        );
                      })
                  ) : (
                      <></>
                  )
            }
          </div>
        </section>

        <section id="info-cols my-5" className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 d-flex flex-column">
              <div className="col-title-wrapper d-flex align-items-center px-4">
                <span className="col-title-skewed"></span>
                <h4 className="col-title m-0">Tadbir</h4>
              </div>
              <Events events={events} key={events} />
            </div>
            <div className="col-sm-12 col-md-6 d-flex flex-column">
              <div className="col-title-wrapper d-flex align-items-center px-4">
                <span className="col-title-skewed"></span>
                <h4 className="col-title m-0">Top maqolalar</h4>
              </div>
              <TopNews articles={topArticles} key={topArticles} />
            </div>
          </div>
        </section>

        <div className="container my-3">
          <h2 className="align-center my-3">Bizning hamkorlarimiz</h2>
          <Slider {...settings}>

            <div>
              <a href="https://europa.eu/european-union/index_en" target="_blank"> <img  width="60%"  src={eu} alt="eu"  loading="lazy"/> </a>
            </div>
            <div>
              <a href="https://www.worldbank.org/en/home" target="_blank">  <img width="90%" height="100%" src={world} alt="world"  loading="lazy"/></a>
            </div>
            <div>
              <a href="https://www.agro.uz/" target="_blank"> <img width="80%" height="100%" src={uz} alt="vazirlik"  loading="lazy"/> </a>
            </div>
            <div>
              <a href="http://www.uzaifsa.uz/" target="_blank">  <img width="100%" height="100%" src={iscad} alt="iscad"  loading="lazy"/> </a>
            </div>
            <div>
              <a href="https://www.annexurefs.com/" target="_blank"> <img width="70%" height="" src={annexure} alt="annexure"  loading="lazy"/> </a>
            </div>
            <div>
              <a href="https://www.kynetec.com" target="_blank"> <img width="100%" height="100%" src={klefman} alt="kleffman"  loading="lazy"/> </a>
            </div>
          </Slider>
        </div>

      </>
    );
  }
}
