import React from "react";
import Events from "./Events";
class Sidebar extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="col-sm-12 col-md-4 position-sticky" style={{top: "90px"}} >
        <div className="col-title-wrapper d-flex align-items-center px-4">
          <span className="col-title-skewed"></span>
          <h4 className="col-title m-0">Tavsiyalar</h4>
        </div>
        <Events />
      </div>
    );
  }
}

export default Sidebar;
