import React, { Component } from "react";
import Loader from "../components/Loader/Loader";
import Sidebar from "../components/Sidebar/Sidebar";
import { getArticle } from "../service/api";
import { exportWord, stripScripts } from "../utils/index";
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, LinkedinShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, TelegramIcon, LinkedinIcon } from "react-share";
let shareUrl = window.location.href;

class Pomegranate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        id: "",
        html: "",
        preview_image: "",
        title: "",
        author: "",
        number_of_views: "",
        updated_at: "",
        created_at: "",
        visibility: "",
        isPinned: "",
        tags: [],
      },
    };
  }
  async getArticle(id) {
    getArticle(
      id,
      (res) => {
        this.setState({ article: res });
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.getArticle(id);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      const {
        match: {
          params: { id },
        },
      } = this.props;
      this.getArticle(id);
    }
  }

  print() {
    var printContents = document.getElementById("article-html").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.document.close(); // necessary for IE >= 10
    window.focus(); //focus(); //
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }
  render() {
    if (this.state.article.id.length === 0) {
      return <Loader />;
    }
    const {
      title,
      author,
      html,
      created_at,
      tags,
    } = this.state.article;
    return (
      <div className="row">
        <div className="col-sm-12 col-md-8" >
          <div style={{ marginBottom: "20px" }}>
            <img src="/assets/img/calendar.svg" alt="" style={{ paddingRight: "5px" }} />{" "}
            <span className="description-label">
              {created_at.split("T")[0]}
            </span>
            <span className="card-tags ulashish" style={{ fontSize: 20, color: "black" }} >
              ulashish:
            </span>
            <span className="card-tags"  >
              <FacebookShareButton
                url={shareUrl}
                hashtag={"#hashtag"}
              >
                <FacebookIcon size={32} round style={{ paddingRight: "5px" }} />
              </FacebookShareButton>
            </span>
            <span className="card-tags"  >
              <TwitterShareButton
                url={shareUrl}
              >
                <TwitterIcon size={32} round style={{ paddingRight: "5px" }} />
              </TwitterShareButton>
            </span>
            <span className="card-tags"  >
              <LinkedinShareButton
                url={shareUrl}
              >
                <LinkedinIcon size={32} round style={{ paddingRight: "5px" }} />
              </LinkedinShareButton>
            </span>
            <span className="card-tags"  >
              <TelegramShareButton
                url={shareUrl}
              >
                <TelegramIcon size={32} round style={{ paddingRight: "5px" }} />
              </TelegramShareButton>
            </span>
          </div>
          <div id="article-html" >
            <h1 className="article-title">
              {title}
            </h1>
            <div
              dangerouslySetInnerHTML={{
                __html: stripScripts(html),
              }}

              id="inserted-html"
            ></div>
            <div style={{ marginLeft: "20px" }}>
              <span
                style={{
                  fontSize: "20px",
                  margin: "5px",
                }}
              >
                Мuallif:  {author}
              </span>
              <a className="print" onClick={() => {
                let h = document
                  .getElementById("article-html")
                  .cloneNode(true);
                exportWord(h, title);
              }} >
                <img src="../assets/icons/print.png"  alt="print"/>
              </a>
            </div>
            <span
              className="card-tags"
              style={{
                fontSize: 20,
                marginLeft: "20px"
              }}
            >
              {tags ? tags.reduce((res, val) => (res + '#' + `${val}  `), '  ') : ''}
            </span>
          </div>
        </div>
        <Sidebar />
      </div>
    );
  }
}

export default Pomegranate;
