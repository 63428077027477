import React, { Component } from "react";
import { getMapProduct } from "../service/api";
import MapCard from "../components/Map/map";
import axios from "axios";
class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null,
      optionSelectedDate: null,
      multiValue: [],
      color: "",
      country_market: "",
      count: "",
      lists: [],
      dates: [],
      prices: [],
      prices_dollar: [],
      prices_local: [],
      prices_local_discount: [],
      charts: [],
      page: 1,
      limit: 10,
      options: [],
      dataList: []
    };
  }


  getMapProduct() {
    getMapProduct(
      (res) => {
        this.setState({
          options: res,
        });
      },
      (err) => {
        // console.log(err);
      }
    );
  }
  handleChange = (selected) => {
    this.postProduct([selected]);
    this.setState({
      optionSelected: selected,
    });
  };

  postProduct(product) {
    let products = [];
    let dates = [];
    if (product.length < 1 || product === undefined) {
      this.setState({
        optionSelected: null,
        optionSelectedDate: null,
        country: []
      })
      this.getMapProduct(this.state.page, this.state.limit);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASEURL}/api/view/maps/product/date`,
          product
        )
        .then((res) => {
          this.setState({
            dates: res.data,
          });
          products.push(this.state.optionSelected.value)
          this.state.dates.map(x => {
            dates.push(x.value)
          })
          let data = {
            product: products,
            date: dates
          }
          this.postProductAllCountry(data)
        });
    }
  }
  postProductAllCountry(data) {
    axios
      .post(
        `${process.env.REACT_APP_BASEURL}/api/view/product/date/maps`,
        data
      )
      .then((res) => {
        this.setState({
          dataList: res.data,
        });
      });
  }
  postProductAndCountry(date) {
    let product = [];
    let dates = [];
    if (date.length < 1 || date === undefined) {
      this.setState({
        optionSelected: null,
        optionSelectedDate: null,
        dates: [],
        dataList: []
      })
      this.getMapProduct(this.state.page, this.state.limit);
    } else {
      product.push(this.state.optionSelected.value)
      date.map(x => {
        dates.push(x.value)
      })
      let data = {
        product: product,
        date: dates
      }
      axios.post(`${process.env.REACT_APP_BASEURL}/api/view/product/date/maps`, data)
        .then((res) => {
          this.setState({
            dataList: res.data,
          });
          console.log(this.state.dataList)
        });
    }
  }
  handleDates = (selected) => {
    this.postProductAndCountry(selected)
    this.setState({
      optionSelectedDate: selected,
    });
  };
  componentDidMount() {
    this.getMapProduct()
  }

  render() {
    return (
      <div>
        <div className="row ">
          <div className="col-md-12 col-sm-12" style={{ marginTop: "50px" }}>
            <h2 className="center d-flex" >O'zbekistonning tabiiy iqlim rayonlari</h2>
            <div className="center d-flex ">
              <div className="d-flex align-items-center">
                <p style={{ backgroundColor: '#FFD9C0', width: "60px", height: "40px", marginLeft: "10px" }}>
                </p>
                <p style={{ marginLeft: "10px" }}>
                  Shimol
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p style={{ backgroundColor: '#E1F1DD', width: "60px", height: "40px", marginLeft: "10px" }}>
                </p>
                <p style={{ marginLeft: "10px" }}>
                  Zarafshon vohasi
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p style={{ backgroundColor: '#FAF4B7', width: "60px", height: "40px", marginLeft: "10px" }}>
                </p>
                <p style={{ marginLeft: "10px" }}>
                  Janub
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p style={{ backgroundColor: '#FDEFEF', width: "60px", height: "40px", marginLeft: "10px" }}>
                </p>
                <p style={{ marginLeft: "10px" }}>
                  Markaz
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p style={{ backgroundColor: '#CDF0EA', width: "60px", height: "40px", marginLeft: "10px" }}>
                </p>
                <p style={{ marginLeft: "10px" }}>
                  Janubi-sharq
                </p>
              </div>
            </div>
            <MapCard data={this.state.dataList} style={{ height: '500px' }}></MapCard>
          </div>
        </div>
      </div>
    );
  }
}
export default Maps;
