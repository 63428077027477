import React from "react";
import { getEvents } from "../../service/api";
import { Link } from "react-router-dom";
import { createReadableDate } from "../../utils";
import Loader from "../Loader/Loader";

class Events extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      loading: false,
    };
  }
  componentDidMount() {
    const { events } = this.props;
    if (!events) {
      this.setState({ loading: true });
      this.getEvents();
    }
  }

  async getEvents(page = 1, limit = 3) {
    getEvents(
      page,
      limit,
      (res) => {
        this.setState({ events: res, loading: false });
      },
      (err) => {
        this.setState({ loading: false });
      }
    );
  }

  render() {
    const { loading } = this.state;
    const { events } = this.props;
    let ev = null;
    if (!events) {
      ev = this.state.events;
    } else {
      ev = events;
    }
    return (
      loading ? (
        <Loader />
      ) : (
        <ul id="events-list" className="d-flex flex-column p-0">
          {Array.isArray(ev) && ev.length > 0
            ? ev.map((event) => {
              return (
                <li key={event.article_id} className="d-flex flex-column my-1 px-1 py-1">
                  <Link
                    to={`/article/${event.article_id}`}
                  >
                    <span className="event-title" >{event.name}</span>
                    <span className="event-date">
                      {createReadableDate(event.date)}
                    </span>
                  </Link>
                </li>
              );
            })
            : ""}
        </ul>
      )
    );
  }
}

export default Events;
