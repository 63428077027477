import React, { Component } from "react";
import AnalysisCardBig from "../components/NewsCard/AnalysisCardBig";
import { getArticles } from "../service/api";
import Pagination from "react-js-pagination";
class AnalysisList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articlesCount: -1,
      articlesAnalysis: [],
      articleType: 'analysis',
      prevArticleType: 'analysis',
      title: "",
      tagsString: "",
      tags: [],
      isLoading: false,
      page: 1,
      limit: 10,
    };
  }

  getArticles(page, limit, typeOfArticle) {
    this.state.title = ''
    let { articlesAnalysis: articles } = this.state;
    this.setState({ isLoading: true });
    getArticles(
      page,
      limit,
      typeOfArticle,
      (res) => {
        if (res.count !== articles.length) {
          this.setState({
            page: page++,
          });
        }
        this.setState({
          articlesCount: res.count,
          articlesAnalysis: res.items,
          isLoading: false,
        });
      },
      (err) => {
        this.setState({
          articles: [],
          isLoading: false,
        });
      }
    );
  }

  PaginatedItems() {
    this.setState({
      pageCount: Math.ceil(this.state.articlesCount / this.state.page),
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    this.getArticles(pageNumber, this.state.limit, 'analysis')
  }
  componentDidMount() {
    this.getArticles(this.state.page, this.state.limit, 'analysis');
  }

  render() {
    const { articlesAnalysis: articles } = this.state;
    return (
      <div className="container h-center">
        <div className="note note-info text-success bg-white mb-3 shadow-3">
          <h3><strong>TAHLILI MAQOLALAR</strong></h3>
        </div>
        <div style={{ padding: "16px 0" }}></div>
        {Array.isArray(articles) && articles.length > 0 ? (
          articles.map((article) => {
            return <AnalysisCardBig article={article} />;
          })
        ) : (
          <></>
        )}

        <div style={{ justifyContent: "center", display: "flex" }}>
          <Pagination
            activePage={this.state.page}
            itemsCountPerPage={this.state.limit}
            totalItemsCount={this.state.articlesCount / this.state.limit * 10}
            pageRangeDisplayed={10}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default AnalysisList;
