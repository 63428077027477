import React from "react";
import { Link } from "react-router-dom";
function ContentCard(props) {
  const { magazine } = props;
  if (!magazine) {
    return null;
  }
  const { name, image, file, id } = magazine;
  const imagePreview = image?.includes("http")
    ? image
    : "https://dummyimage.com/480/ced4da/6c757d.jpg";
  return (
    <>
      <Link
        to={{
          pathname: "/content/" + id,
          state: {
            name: name,
            file: file,
          },
        }}
      >
        <div className="card-magazine my-3 d-flex flex-wrap  justify-content-center ">
          <div className="card-image">
            <img src={imagePreview} alt="" />
          </div>
          <div className="card-info " >
            <p className="card-title"  >{name}</p>
          </div>
        </div>
      </Link>
    </>
  );
}

export default ContentCard;
