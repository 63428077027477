import React, { Component } from "react";
import CompanyCard from "../components/CompanyCard/CompanyCard";
import Pagination from "react-js-pagination";
import {
  getCompanies,
  filterCompanies,
  getRegions,
} from "../service/api";

class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articlesCount: -1,
      count: null,
      companies: [],
      regions: [],
      region: "",
      search: "",
      products: [],
      productsString: "",
      isLoading: false,
      page: 1,
      limit: 10,
      setPageCount: null,
    };
  }

  filterCompanies(state) {
    const that = this;
    const { search, products, region } = state;
    this.state.page = 1;
    filterCompanies(
      1,
      20,
      {
        name: search,
        region: region,
        products: products,
      },
      (res) => {

        if (!res.items) {
          this.state.companies = [];
        } else {
          this.setState({
            companies: res.items,
          });
        }
      },
      (err) => {
        this.setState({
          companies: [],
        });
      }
    );
  }

  async getCompanies(page, limit) {
    this.state.search = "";
    this.state.productsString = "";
    getCompanies(
      page,
      limit,
      (res) => {
        this.setState({
          count: res.count,
          companies: res.items,
        });
      },
      (err) => {
        // console.log(err);
      }
    );
  }
  async getRegions() {
    getRegions(
      (res) => {
        this.setState({
          regions: res,
        });
      },
      (err) => {
        // console.log(err);
      }
    );
  }



  handleSelect(e) {
    this.setState({
      region: e.target.value,
    });
  }

  handleProducts(e) {
    const products = e.target.value;
    const productsArr = products.replace(" ", "").split(",");
    this.setState({
      products: productsArr,
      productsString: products,
    });
  }

  handleSearch(e) {
    const value = e.target.value;
    this.setState({
      search: value,
    });
  }

  componentDidMount() {
    this.getCompanies(this.state.page, this.state.limit);
    this.getRegions();
    // this.getActivities();
  }

  PaginatedItems() {
    this.setState({
      pageCount: Math.ceil(this.state.count / this.state.page),
    });
  }
  handlePageClick = (event) => {
    this.state.page = event.selected + 1;
    this.getCompanies(this.state.page, this.state.limit);
  };

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    this.getCompanies(pageNumber, this.state.limit)
  }
  render() {
    const {
      companies
    } = this.state;
    return (
      <div className="container h-center">
        <div className="note note-info text-success bg-white mb-3 shadow-3">
          <h3><strong>KOMPANIYALAR</strong></h3>
        </div>

        {/* <div
          className="filter d-flex flex-column px-2 py-3"
          style={{
            backgroundColor: "var(--light-gray)",
          }}
        >
          <h3 className="mb-3 px-3">Qidirish</h3>
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <Input
                id="company-name"
                onChange={this.handleSearch.bind(this)}
                placeholder="Shirinmeva"
                value={search}
                label="Kompaniya nomi"
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <Input
                id="product-tags"
                onChange={this.handleProducts.bind(this)}
                placeholder="Vergul оrqali qo'shish"
                value={productsString}
                label="Mahsulotlar bo'yicha"
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <div className="d-flex flex-column-reverse">
                <select
                  onChange={this.handleSelect.bind(this)}
                  value={region}
                  style={{ width: "100%" }}
                  className="select"
                  id="select-activities"
                  name="region"
                >
                  <option selected value={"region"}>
                    Iltimos tanlang
                  </option>
                  {Array.isArray(regions) && regions.length > 0
                    ? regions.map((region) => {
                      return (
                        <option value={region.id}>{region.region}</option>
                      );
                    })
                    : null}
                </select>
                <label htmlFor="select-activities" className="select-label">
                  Viloyatlar bo'yicha
                </label>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <button
                className="filter-btn mx-2"
                type="button"
                onClick={this.getCompanies.bind(this, 1, 20)}
                style={{
                  opacity: productsString || search ? 1 : 0,
                  marginLeft: "10px",
                }}
              >
                Qayta o'rnatish
              </button>

              <button
                className="filter-btn mx-2"
                type="button"
                onClick={() => this.filterCompanies(this.state)}
              >
                Qidirmoq
              </button>
            </div>
          </div>
        </div> */}
        <div style={{ padding: "16px 0" }}></div>
        {Array.isArray(companies) && companies.length > 0 ? (
          companies.map((company) => {
            return <CompanyCard company={company} />;
          })
        ) : (
          <></>
        )}
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Pagination
            activePage={this.state.page}
            itemsCountPerPage={this.state.limit}
            totalItemsCount={this.state.count / this.state.limit * 10}
            pageRangeDisplayed={10}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default Companies;
