import React from "react";
import { Link } from "react-router-dom";
import { createReadableDate } from "../../utils";

function PresentationCardBig(props) {
  const { article } = props;
  if (!article) {
    return null;
  }
  const handle = () => {
    window.open(article.file, '_blanker')
  }
  const {
    id,
    author,
    image,
    language,
    name,
    created_at,
  } = article;

  const imagePreview = image?.includes("http")
    ? image
    : "./assets/img/statistics.jpg";
  return (
    <div
      className="static-card  my-3 px-3 py-2"
      style={{ color: "inherit" }}
    >
      <div className="row d-flex">
        <img
          className="stat-img center"
          src={imagePreview}
          alt={name}
          key={name}
        />
        <div
          className="col-sm-12 col-md-10 d-flex "
        >
          <div className=" flex-column">
            <br />
            <span className="description-label">
              <img src="./assets/img/calendar.svg" alt="" />{" "}
              {createReadableDate(created_at)}
              <span style={{ marginLeft: "10px" }}>   |  {author}</span>
              <span style={{ marginLeft: "10px" }}>   |  {language}</span>
              <span onClick={handle} style={{ marginLeft: "10px" }}>
                <a className="pdf-link" >
                  yuklab olish  <img src="../assets/img/pdf.png" height="20px" alt="" />
                </a>
              </span>
            </span>
            <br />
            <Link to={`/presentation/${id}`}>
              <h4 className="company-name" style={{ cursor: "pointer", marginTop: "15px" }}>
                {name}
              </h4>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PresentationCardBig;
