import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import UserCard from "../components/UserCard/UserCard";
import { getMembers } from "../service/api";
import { getWindowDimensions } from "../utils";
const eu = window.location.origin + "/assets/img/eu.jpg";
const world = window.location.origin + "/assets/img/word.jpeg";
const uz = window.location.origin + "/assets/img/Uzbekistan_Gerb.png";
const iscad = window.location.origin + "/assets/img/iscad.jpg";
export default () => {
  const [members, setData] = useState([]);
  const [width, setWidth] = useState([]);

  useEffect(() => {
    getMembers((data) => {
      setData(data.items);
    });
    const { width } = getWindowDimensions();
    setWidth(width);
  }, []);

  return (
    <div className="container" style={{ marginTop: 20, marginBottom: 20 }}>
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div className="row d-flex justify-content-around">
            <div
              className="col-md-3"
              style={{ textAlign: "center", alignSelf: "end" }}
            >
              <img
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
                src={eu}
                height="125px"
              />
            </div>
            <div
              className="col-md-3"
              style={{ textAlign: "center", alignSelf: "end" }}
            >
              <img
                height="120px"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
                src={world}
              />
            </div>
            <div
              className="col-md-3"
              style={{ textAlign: "center", alignSelf: "end" }}
            >
              <img
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
                src={uz}
                height="120px"
              />
            </div>
            <div
              className="col-md-3"
              style={{ textAlign: "center", alignSelf: "end" }}
            >
              <img
                style={{ justifyContent: "center", alignItems: "center" }}
                src={iscad}
                height="94px"
              />
            </div>
          </div>
          <p>
            <strong>
              {" "}
              Yevropa Ittifoqi Komissiyasi, Jahon banki, Oʼzbekiston
              Respublikasi Qishloq xoʼjaligi vazirligi{" "}
            </strong>
            hamda Oʼzbekiston Respublikasi Qishloq xoʼjaligi vazirligi
            huzuridagi Xalqaro strategik rivojlanish va oziq-ovqat tadqiqotlari
            markazi koʼmagida amalga oshirilayotgan{" "}
            <strong>
              {" "}
              “Oʼzbekistonda meva-sabzavotchilik marketingi boʼyicha texnik
              koʼmak”
            </strong>{" "}
            loyihasining maqsadi Oʼzbekiston Respublikasi Hukumatiga
            meva-sabzavotchilik sohasida hosildorlik, serdaromadlik va
            raqobatbardoshlikni yaxshilash borasida koʼmak berishdan iborat.
          </p>
          <p style={{ fontWeight: "bold" }}>
            Loyihaning davomiyligi: 2021 yil 25 avgustdan 2023 yil 25
            avgustgacha (3 yil)
          </p>
          <p>
            Loyiha davomida Jahon meva va sabzavotlar bozorida narxlar
            dinamikasi, talab va takliflar, eksport va import bilan bog‘liq
            qiziqarli ma’lumotlar, bu borada O‘zbekiston salohiyati va ulushi,
            marketing va iqtisodiyotning boshqa sohalaridagi dolzarb innovatsion
            g‘oyalar, tahlillar, mashhur xalqaro va mahalliy ekspertlar bilan
            suhbatlar — bularning barchasini <strong> “ShirinMeva”</strong>{" "}
            web-sayti orqali kuzatib, loyiha yangiliklaridan xabardor bo‘lib
            boring!
          </p>
          <strong>
            Web-sayt Xalqaro rivojlanish va taraqqiyot banki (Jahon Banki)
            boshqaruvi ostida Yevropa Ittifoqi tomonidan moliyalashtiriladigan
            “O‘zbekistonda meva-sabzavotchilik marketingi bo‘yicha texnik
            ko‘mak” loyihasi doirasida faoliyat yuritadi. Loyihani amalga
            oshirish “Annexure Financial Solutions” va “Kleffmann Group”
            konsorsiumiga topshirilgan.
          </strong>
          <br />
          <p style={{ fontWeight: "bold" }}>
            Meva-sabzavot marketing sohasini biz bilan birga kuzating!
          </p>
          <br />
          <h3 style={{ textAlign: "center" }}>
            Loyihaning hududiy vakolatxonalari haqida qisqacha ma'lumot
          </h3>

          <div className="col-12">
            <table style={{ width: "100%", border: " 1px solid  black" }}>
              <tr style={{ borderBottom: " 1px solid  black" }}>
                <th style={{ padding: "10px" }}>№</th>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "10px",
                  }}
                >
                  Vakolatxona manzili
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "10px",
                  }}
                >
                  Mas'ul xodim
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "10px",
                  }}
                >
                  Telefon raqami
                </td>
              </tr>
              <tr style={{ borderBottom: " 1px solid  black" }}>
                <th style={{ padding: "10px" }}>1</th>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "10px",
                  }}
                >
                  Toshkent sh., Mirzo-Ulug’bek tumani, Niyozbek yo’li ko’chasi 30
                </td>
                <td
                  style={{
                    borderLeft: " 1px solid  black",
                    padding: "10px",
                  }}
                >
                  Sevara Ismailova
                </td>
                <td
                  style={{
                    borderLeft: " 1px solid  black",
                    padding: "10px",
                  }}
                >
                  +99871 202-11-21
                </td>
              </tr>
              <tr style={{ borderBottom: " 1px solid  black" }}>
                <th style={{ padding: "10px" }}>2</th>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "10px",
                  }}
                >
                  Samarqand sh., Bobur Mirzo ko‘chasi 9-uy.
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "10px",
                  }}
                >
                  Norjigit Nishonov
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "10px",
                  }}
                >
                  +99891 534-27-09
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid  black" }}>
                <th style={{ padding: "10px" }}>3</th>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "10px",
                  }}
                >
                  Buxoro sh., Q.Murtazoev ko‘chasi, 5-uy, Hamkor Biznes Markazi,
                  2-qavat, 16-ofis.
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "10px",
                  }}
                >
                  Mirfayz Narziev
                </td>
                <td
                  style={{
                    borderLeft: "1px solid  black",
                    padding: "10px",
                  }}
                >
                  +99878 770-07-92
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="col-sm-12 col-md-3"></div>
      </div>
      <div className="col-sm-12 col-md-12">
        <h2>Bizning komanda</h2>
        <Carousel
            autoFocus={true}
            autoPlay={true}
            centerMode={true}
            centerSlidePercentage={width > 768 ? 24 : 100}
            infiniteLoop={true}
            showArrows={true}
            showIndicators={false}
            emulateTouch={true}
            useKeyboardArrows={true}
        >
          {Array.isArray(members) && members.length > 0
            ? members.map((member) => {
                return <UserCard user={member} key={member.name} />;
              })
            : null}
        </Carousel>
      </div>
    </div>
  );
};
