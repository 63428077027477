import React, { Component } from "react";
import { getChartProduct } from "../service/api";
import ChartCard from "../components/ChartCard/Chart";
import ChartCardUZB from "../components/ChartCardUZB/Chart";
import axios from "axios";
import Select from 'react-select'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null,
      optionSelectedCountry: null,
      multiValue: [],
      color: "",
      country_market: "",
      count: "",
      lists: [[
        {
          id: 1,
          country: "",
          product: "",
          volume: 1,
          prices: [
            {
              date: "2021-10-15",
              price: 2
            },
            {
              date: "2021-10-30",
              price: 1
            },
            {
              date: "2021-11-15",
              price: 1
            }
          ]
        }
      ]],
      listsUZB: [[
        {
          id: 1,
          country: "",
          product: "",
          volume: 1,
          prices: [
            {
              date: "2021-10-15",
              price: 2
            },
            {
              date: "2021-10-30",
              price: 1
            },
            {
              date: "2021-11-15",
              price: 1
            }
          ]
        }
      ]],
      dates: [],
      prices: [],
      prices_dollar: [],
      prices_local: [],
      prices_local_discount: [],
      charts: [],
      page: 1,
      limit: 10,
      options: [],
      country: [],
      countryUzb: [],
      startDate: "01-01-2021",
      endDate: "01-01-2023",

    };
  }
  selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }
  componentDidMount() {
    this.getChartProduct();
    this.getChartDates()
  }

  getChartDates() {
    axios.get(`${process.env.REACT_APP_BASEURL}/api/all/chart/dates`).then(res => {
      let startDate, endDate;
      startDate = res.data.sort((a, b) => a - b)[0].date
      endDate = res.data.slice(-1)[0].date
      this.setState({
        startDate: new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        endDate: new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
      })
    })
  }
  getChartByDates(startDate, endDate) {
    let countryUZB = [
      {
        "value": "Uzbekistan",
        "label": "Uzbekistan"
      }
    ]
    let tempList = []
    let tempListUzb = []
    this.setState({
      lists: [],
      listsUZB: []
    })
    if (this.state.optionSelected && this.state.optionSelectedCountry) {
      axios.post(`${process.env.REACT_APP_BASEURL}/api/single/date/chart`, { product: this.state.optionSelected[0].value, country: this.state.optionSelectedCountry, startDate: startDate._d, endDate: endDate._d }).then(res => {
        this.state.optionSelected.map((x) => {
          tempList.push(res.data.filter(z => z.product === x.value))
        })
        this.setState({
          lists: tempList,
        });
      }).then(() => {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/single/date/chart`, { product: this.state.optionSelected[0].value, country: countryUZB, startDate: startDate._d, endDate: endDate._d }).then(res => {
          this.state.optionSelected.map((x) => {
            tempListUzb.push(res.data.filter(z => z.product === x.value))
          })
          this.setState({
            listsUZB: tempListUzb,
          });
        })
      })
    } else {
      axios.post(`${process.env.REACT_APP_BASEURL}/api/single/date/chart`, { product: this.state.optionSelected[0].value, country: this.state.country, startDate: startDate._d, endDate: endDate._d }).then(res => {
        this.state.optionSelected.map((x) => {
          tempList.push(res.data.filter(z => z.product === x.value))
        })
        this.setState({
          lists: tempList,
        });
      }).then(() => {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/single/date/chart`, { product: this.state.optionSelected[0].value, country: countryUZB, startDate: startDate._d, endDate: endDate._d }).then(res => {
          this.state.optionSelected.map((x) => {
            tempListUzb.push(res.data.filter(z => z.product === x.value))
          })
          this.setState({
            listsUZB: tempListUzb,
          });
        })
      })
    }

  }
  getChartByDatesUZB(startDate, endDate) {
    let countryUZB = [
      {
        "value": "Uzbekistan",
        "label": "Uzbekistan"
      }
    ]
    let tempList = []
    if (this.state.optionSelected) {
      axios.post(`${process.env.REACT_APP_BASEURL}/api/single/date/chart`, { product: this.state.optionSelected[0].value, country: countryUZB, startDate: startDate._d, endDate: endDate._d }).then(res => {
        this.state.optionSelected.map((x) => {
          tempList.push(res.data.filter(z => z.product === x.value))
        })
        this.setState({
          listsUZB: tempList,
        });
      })
    } else {
      axios.post(`${process.env.REACT_APP_BASEURL}/api/single/date/chart`, { product: this.state.optionSelected[0].value, country: countryUZB, startDate: startDate._d, endDate: endDate._d }).then(res => {
        this.state.optionSelected.map((x) => {
          tempList.push(res.data.filter(z => z.product === x.value))
        })
        this.setState({
          listsUZB: tempList,
        });
      })
    }

  }
  setDates = (e, { startDate, endDate }) => {
    this.setState({
      startDate: startDate.format('MM-DD-YYYY'),
      endDate: endDate.format('MM-DD-YYYY')
    });
    this.getChartByDates(startDate, endDate);
  };

  handleSelect(ranges) {
    this.selectionRange = {
      startDate: ranges.startDate,
      endDate: ranges.endDate,
      key: 'selection'
    }
  }
  options = [
    { value: "ocean1", label: "Ocean" },
    { value: "blue", label: "Blue" },
    { value: "purple", label: "Purple" },
    { value: "red", label: "Red" },
    { value: "orange", label: "Orange" },
    { value: "yellow", label: "Yellow" },
    { value: "green", label: "Green" },
    { value: "forest", label: "Forest" },
    { value: "slate", label: "Slate" },
    { value: "silver", label: "Silver" },
  ];

  colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        colorColor: isDisabled ? "red" : "blue",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };
  handleChange = (selected) => {
    this.postProduct([selected]);
    this.postDate([selected]);
    this.setState({
      optionSelected: [selected],
    });
  };
  handleCountry = (selected) => {
    this.postProductAndCountry(selected)
    this.setState({
      optionSelectedCountry: selected,
    });
  };
  postProduct(product) {
    let products = [];
    let countries = [];
    let countryUzb = [];
    if (product.length < 1 || product === undefined) {
      this.setState({
        optionSelected: null,
        optionSelectedCountry: null,
        country: []
      })
      this.setState({
        lists: [[
          {
            id: 1,
            country: "",
            product: "",
            volume: 1,
            prices: [
              {
                date: "2021-10-15",
                price: 2
              },
              {
                date: "2021-10-30",
                price: 1
              },
              {
                date: "2021-11-15",
                price: 1
              }
            ]
          }
        ]],
        listsUZB: this.state.lists = [[
          {
            id: 1,
            country: "",
            product: "",
            volume: 1,
            prices: [
              {
                date: "2021-10-15",
                price: 2
              },
              {
                date: "2021-10-30",
                price: 1
              },
              {
                date: "2021-11-15",
                price: 1
              }
            ]
          }
        ]]
      })

    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASEURL}/api/single/product/country/chart`,
          product
        )
        .then((res) => {
          this.setState({
            country: res.data.filter(x => x.value !== 'Uzbekistan'),
            countryuzb: res.data.filter(x => x.value === 'Uzbekistan'),
          });
          products.push(this.state.optionSelected[0].value);
          this.state.country.map(x => {
            countries.push(x.value)
          })
          this.state.countryuzb.map(x => {
            countryUzb.push(x.value)
          })
          let data = {
            product: products,
            country: countries
          }
          let dataUzb = {
            product: products,
            country: countryUzb
          }
          this.postProductAllCountry(data, dataUzb)
        });
    }
  }
  postDate(product) {
    if (product.length < 1 || product === undefined) {
      this.setState({
        optionSelected: null,
        optionSelectedCountry: null,
        country: []
      })
      this.setState({
        lists: [[
          {
            id: 1,
            country: "",
            product: "",
            volume: 1,
            prices: [
              {
                date: "2021-10-15",
                price: 2
              },
              {
                date: "2021-10-30",
                price: 1
              },
              {
                date: "2021-11-15",
                price: 1
              }
            ]
          }
        ]],
        listsUZB: this.state.lists = [[
          {
            id: 1,
            country: "",
            product: "",
            volume: 1,
            prices: [
              {
                date: "2021-10-15",
                price: 2
              },
              {
                date: "2021-10-30",
                price: 1
              },
              {
                date: "2021-11-15",
                price: 1
              }
            ]
          }
        ]]
      })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASEURL}/api/date/chart`,
          product
        )
        .then((res) => {
          let startDate, endDate;
          startDate = res.data.sort((a, b) => a - b)[0].date
          endDate = res.data.slice(-1)[0].date
          this.setState({
            startDate: new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            endDate: new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
          })

        });
    }
  }

  postProductAllCountryUZB(data) {
    let list = [];
    let tempList = []
    axios.post(
      `${process.env.REACT_APP_BASEURL}/api/single/view/product/all/chart`,
      data
    )
      .then((res) => {
        data.product.map((x, i) => {
          res.data.map(y => {
            if (y.product === x) {
              list.push(y.list[0])
            }
          })
        })
        data.product.map((x) => {
          tempList.push(list.filter(z => z.product === x))
        })
        this.setState({
          listsUZB: tempList,
        });
      });
  }
  postProductAllCountry(data, dataUzb) {
    let list = [];
    let tempList = [];
    let listUZb = [];
    let tempListUzb = []
    axios
      .post(
        `${process.env.REACT_APP_BASEURL}/api/single/view/product/all/chart`,
        data
      )
      .then((res) => {
        data.product.map((x, i) => {
          res.data.map(y => {
            if (y.product === x) {
              list.push(y.list[0])
            }
          })
        })
        data.product.map((x) => {
          tempList.push(list.filter(z => z.product === x))
        })
        this.setState({
          lists: tempList,
        });
      }).then(() => {
        axios
          .post(
            `${process.env.REACT_APP_BASEURL}/api/single/view/product/all/chart`,
            dataUzb
          )
          .then((res) => {
            data.product.map((x, i) => {
              res.data.map(y => {
                if (y.product === x) {
                  listUZb.push(y.list[0])
                }
              })
            })
            data.product.map((x) => {
              tempListUzb.push(listUZb.filter(z => z.product === x))
            })
            this.setState({
              listsUZB: tempListUzb,
            });
          });
      })


  }
  postProductAndCountry(country) {
    let list = [];
    let tempList = []
    let product = [];
    let countries = [];
    if (country.length < 1 || country === undefined) {
      this.setState({
        optionSelected: null,
        optionSelectedCountry: null,
        country: []
      })
      this.setState({
        lists: [[
          {
            id: 1,
            country: "",
            product: "",
            volume: 1,
            prices: [
              {
                date: "2021-10-15",
                price: 2
              },
              {
                date: "2021-10-30",
                price: 1
              },
              {
                date: "2021-11-15",
                price: 1
              }
            ]
          }
        ]],
        listsUZB: this.state.lists = [[
          {
            id: 1,
            country: "",
            product: "",
            volume: 1,
            prices: [
              {
                date: "2021-10-15",
                price: 2
              },
              {
                date: "2021-10-30",
                price: 1
              },
              {
                date: "2021-11-15",
                price: 1
              }
            ]
          }
        ]]
      })
      this.postDate(this.state.optionSelected)
    } else {
      this.state.optionSelected.map(x => {
        product.push(x.value)
      })
      country.map(x => {
        countries.push(x.value)
      })
      let data = {
        product: product,
        country: countries
      }
      axios.post(`${process.env.REACT_APP_BASEURL}/api/single/view/product/country/chart`, data)
        .then((res) => {
          product.map((x, i) => {
            res.data.map(y => {
              if (y.product === x) {
                list.push(y.list[0])
              }
            })
          })
          product.map((x) => {
            tempList.push(list.filter(z => z.product === x))
          })
          this.setState({
            lists: tempList,
          });
        });
    }
  }
  getChartProduct() {
    getChartProduct(
      (res) => {
        this.setState({
          options: res,
        });
      },
      (err) => {
        // console.log(err);
      }
    );
  }
  handleMultiChange(option) {
    this.setState((state) => {
      return {
        multiValue: option,
      };
    });
  }
  onChange = ranges => {
    // console.log(ranges);
  };
  styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
  };

  lineOptions = {
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            userCallback(value) {
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(".");
              return `Rp.${value}`;
            },
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  };


  render() {
    return (
      <div>
        <div className="row">
          <h4 className="center d-flex" style={{ color: "red" }}>Sahifa modernizatsiya qilinmoqda!</h4>
          <h3 className="center d-flex">Dunyo bo'yicha ho'l meva va sabzavotlarning oylik o'rtacha hisoblangan narxlari</h3>
          <h5 className=" d-flex">Birlik qiymati "$/kg"</h5>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <Select options={this.state.options}
              placeholder="Meva sabzavotlarni tanlang"
              onChange={this.handleChange}
              value={this.state.optionSelected} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <Select isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              placeholder="Davlatlarni tanlang"
              onChange={this.handleCountry}
              options={this.state.country} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <DateRangePicker
              initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate }}
              onApply={this.setDates}
              locale={{ format: "DD-MM-YYYY" }}
              autoApply={true}
              singleDatePicker

            >
              <input
                type="text"
                value={this.state.startDate + "-" + this.state.endDate}
                className="form-control"
              />
            </DateRangePicker>
          </div>
        </div>
        <div className="row" >
          <div className="col-md-6 col-lg-6 col-sm-12">
            {Array.isArray(this.state.lists) && this.state.lists.length > 0 ? (
              this.state.lists.map((chart) => {
                return (
                  <div className="col-12 col-sm-12 col-md-12 center">
                    <ChartCard chart={chart} />
                  </div>
                );
              })
            ) : (
              <h4></h4>
            )}
          </div>
          <div className="col-md-6 col-lg-6 col-sm-12">
            {Array.isArray(this.state.listsUZB) ? (
              this.state.listsUZB.map((chartUzb) => {
                return (
                  <div className="col-12 col-sm-12 col-md-12 center">
                    <ChartCardUZB chart={chartUzb} />
                  </div>
                );
              })
            ) : (
              <h4></h4>
            )}
          </div>

        </div>
        <h4 >Loyihaning xalqaro ekspertlari mustaqil tadqiqoti asosida tayyorlangan</h4>
      </div>
    );
  }
}
export default Charts;
