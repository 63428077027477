import React, { Component } from "react";
import NewsCardBig from "../components/NewsCard/NewsCardBig";
import { getArticles } from "../service/api";
import Pagination from "react-js-pagination";
class ArticleList extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    const articleType = urlParams.get("article_type");
    this.state = {
      articlesCount: -1,
      articles: [],
      articleType: 'news',
      prevArticleType: articleType,
      title: "",
      tagsString: "",
      tags: [],
      page: 1,
      limit: 10,
      isLoading: false,
    };
  }

  async getArticles(page, limit, typeOfArticle) {
    this.state.title = ''
    let { articles } = this.state;
    this.setState({ isLoading: true });
    getArticles(
      page,
      limit,
      typeOfArticle,
      (res) => {
        if (res.count !== articles.length) {
          this.setState({
            page: page++,
          });
        }
        this.setState({
          articlesCount: res.count,
          articles: res.items,
          isLoading: false,
        });
      },
      (err) => {
        this.setState({
          articles: [],
          isLoading: false,
        });
      }
    );
  }

  PaginatedItems() {
    this.setState({
      pageCount: Math.ceil(this.state.articlesCount / this.state.page),
    });
  }
  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    this.getArticles(pageNumber, this.state.limit, 'news')
  }
  componentDidMount() {
    this.getArticles(this.state.page, this.state.limit, 'news');
  }

  componentDidUpdate(prevProps, prevState) {
    const urlParams = new URLSearchParams(window.location.search);
    const articleType = urlParams.get("article_type");
    if (this.props.location !== prevProps.location) {
      this.setState({
        prevArticleType: this.state.articleType,
        articleType: articleType,
        articles: [],
        articlesCount: -1,
        page: 1,
      });
    }
  }

  render() {
    const { articles } = this.state; 
    return (
      <>
        <div className="container h-center">
          <div className="note note-info text-success bg-white mb-3 shadow-3">
            <h3><strong>YANGILIKLAR</strong></h3>
          </div>
          <div style={{ padding: "10px 0" }}></div>
          {Array.isArray(articles) && articles.length > 0 ? (
            articles.map((article) => {
              return <NewsCardBig article={article} />;
            })
          ) : (
            <></>
          )}
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Pagination
            activePage={this.state.page}
            itemsCountPerPage={this.state.limit}
            totalItemsCount={this.state.articlesCount / this.state.limit * 10}
            pageRangeDisplayed={10}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      </>

    );
  }
}

export default ArticleList;
