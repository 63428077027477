import React from "react";
function CompanyCard(props) {
  const { company } = props;
  if (!company) {
    return null;
  }
  const {
    name,
    phone_number,
    mail_web,
    address,
    eksport,
  } = company;
  const imagePreview = company.image ? company.image : "./assets/img/no_company_preview.png";
  <div>
    <h1>Barcha hamkorlar bilan tanishish</h1>
  </div>
  return (

    <div className="company-cards my-3" style={{ color: "inherit" }}>
      <div className="row">
        <div className="col-sm-12 col-md-3 d-flex center">
          <img
            className="company-images"
            src={imagePreview}
            alt={name}
          />
        </div>
        <div className="col-sm-12 col-md-9">
          <div className="d-flex column ">
            <span className="company-names">
              <strong>Nomi:</strong> {name}
            </span>
            <span className="company-names">
              <strong>Manzil:</strong> {address}
            </span>
            <span className="company-names">
              {phone_number != '-' ? (
                <>
                  <strong>Telifon raqam:</strong> +998 {phone_number}
                </>
              ) : (
                <>
                  <strong>Telifon raqam: </strong> -
                </>
              )}
            </span>

            <span className="company-names">
              {mail_web != '-' ? (
                <>
                  <strong> Email yoki website :</strong>  {mail_web}
                </>
              ) : (
                <>
                  <strong> Email yoki website : </strong> -
                </>
              )}
            </span>
            <span className="company-names">
              <strong>Kompaniya  eksportyorlari:</strong> {eksport}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyCard;
