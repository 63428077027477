import React, { Component } from "react";
import { getMagazines } from "../service/api";
import ContentCard from "../components/ContentCard/Content";
class ContentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      magazine: [],
      magazineCount: "",
      isLoading: false,
    };
  }

  async getMagazines() {
    getMagazines(
      (res) => {
        this.setState({
          magazine: res.items,
          magazineCount: res.count,
        });
      },
    );
  }
  componentDidMount() {
    this.getMagazines();
  }

  render() {
    return (
      <div className="container h-center">

          <div className="note note-info text-success  mb-3 shadow-6">
              <h3><strong>JURNALLAR</strong></h3>
          </div>
        <div style={{ padding: "16px 0" }}></div>
        <div className="card-list row">
          {Array.isArray(this.state.magazine) && this.state.magazine.length > 0 ? (
            this.state.magazine.map((magazine) => {
              return (
                <div key={magazine.id} className="col-12 col-sm-6 col-md-3 center">
                  <ContentCard key={magazine.id} magazine={magazine} />
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default ContentList;
