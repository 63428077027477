import React from "react";
import { Link } from "react-router-dom";
function VideoCard(props) {
  const { video } = props;
  if (!video) {
    return null;
  }
  const { name, image, id } = video;
  const imagePreview = image?.includes("http")
    ? image
    : "https://dummyimage.com/480/ced4da/6c757d.jpg";
  return (
    <>
      <Link
        to={`/video/${id}`}>
        <div className="card-video my-3 d-flex flex-wrap  "  >
          <div className="card-image">
            <img src={imagePreview} alt={name} />
          </div>
          <div className="card-info mt-2 p-3">
            <h3 className="card-title" style={{ textAlign: "center" }}>{name}</h3>
          </div>
        </div>
      </Link>
    </>
  );
}

export default VideoCard;
