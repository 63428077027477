import React, { Component } from "react";
import Loader from "../components/Loader/Loader";
import { getMagazine } from "../service/api";
import ReactPdf from "../components/react-pdf/react-pdf"
class Magazine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      file: '',
    };
  }

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages
    });
  };

  async getMagazine(id) {
    getMagazine(
      id,
      (res) => {
        this.setState({
          id: res.id,
          file: res.file
        });
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.getMagazine(id);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      const {
        match: {
          params: { id },
        },
      } = this.props;
      this.getMagazine(id);
    }
  }

  render() {
    const isFile = this.state.file;
    return (
      <div className="row">
        <div className="col-sm-12 col-md-12" >
          {isFile
            ? <ReactPdf props={this.state.file} />
            : <Loader></Loader>
          }
        </div>
      </div>
    );
  }
}

export default Magazine;
