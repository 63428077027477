import receiver, { queryParamsBuilder } from "../utils";

export const ARTICLE_TYPE = {
  NEWS: "News",
  ARTICLE: "Article",
  ANALYSIS: "Analysis",
  EVENT: "Event",
  LEGAL: "Legal",
  EXPORT_SUPPORT: "Export support (export adviser)",
  EXPORT_RULES: "Export support (export adviser)",
  UZ_EXPORT_RULES: "Export rules in Uzbekistan",
  MARKETS: "Potential markets",
  ANALITYCS: "Analytics",
  FRUITS_MARKET: "Fruit and vegetable market prices",
  ABOUT_VARIETIES: "About varieties",
  MAGAZINE: "The Magazine ShirinMeva",
  LEARNING: "Learning by doing",
  UZ_EXPORT: "Practices on export in Uzbekistan",
  WORLD_EXPORT: "Practices on export on the world",
};

function getArticle(id, onSuccess, OnError) {
  receiver(
    `/api/article/${id}`,
    { method: "GET", credentials: "include", redirect: "follow" },
    onSuccess,
    OnError
  );
}

function getStatistic(id, onSuccess, OnError) {
  receiver(
    `/api/analysis/${id}`,
    { method: "GET", credentials: "include", redirect: "follow" },
    onSuccess,
    OnError
  );
}
function getPresentationId(id, onSuccess, OnError) {
  receiver(
    `/api/presentation/${id}`,
    { method: "GET", credentials: "include", redirect: "follow" },
    onSuccess,
    OnError
  );
}

function getCompany(id, onSuccess, OnError) {
  receiver(`/api/company/${id}`, { method: "GET" }, onSuccess, OnError);
}

function getArticles(
  page = 1,
  limit = 20,
  articleType = ARTICLE_TYPE.ARTICLE,
  onSuccess,
  OnError
) {
  receiver(
    `/api/article?limit=${limit}&page=${page}&section=${articleType}`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}

function filterArticles(
  page = 1,
  limit = 20,
  queryParams = { title: "", tags: "", section: "" },
  onSuccess,
  OnError
) {
  const query = queryParamsBuilder(queryParams);
  receiver(
    `/api/article-filter?page=${page}&limit=${limit}${query}`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}

function filterStatics(page = 1, limit = 20, onSuccess, OnError) {
  receiver(
    `/api/analysis-filter?page=${page}&limit=${limit}`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}

function getCompanies(page = 1, limit = 20, onSuccess, OnError) {
  receiver(
    `/api/company?limit=${limit}&page=${page}`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}

function filterCompanies(
  page,
  limit,
  queryParams = { name: "", activity_type: "", products: [] },
  onSuccess,
  OnError
) {
  const query = queryParamsBuilder(queryParams);
  receiver(
    `/api/company-filter?limit=${limit}&page=${page}${query}`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}

function getActivities(page = 1, limit = 20, onSuccess, OnError) {
  receiver(`/api/activity-types`, { method: "GET" }, onSuccess, OnError);
}

function getRegions(onSuccess, OnError) {
  receiver(`/api/company/region`, { method: "GET" }, onSuccess, OnError);
}

function getEvents(page = 1, limit = 20, onSuccess, OnError) {
  receiver(`/api/events`, { method: "GET" }, onSuccess, OnError);
}

function getMagazines(onSuccess, OnError) {
  receiver(
    `/api/magazine?limit=100&page=1`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}

function getMagazinesTest(onSuccess, OnError) {
  receiver(
    `/api/magazine-test?limit=100&page=1`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}

function getMagazine(id, onSuccess, OnError) {
  receiver(
    `/api/magazine/${id}`,
    { method: "GET", credentials: "include", redirect: "follow" },
    onSuccess,
    OnError
  );
}

function getCrops(id, onSuccess, OnError) {
  receiver(
    `/api/crops/${id}`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}
function getContenByMagazineId(id, onSuccess, OnError) {
  receiver(
    `/api/content/magazine/${id}`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}

function getVideos(onSuccess, OnError) {
  receiver(`/api/video/sections/all`, { method: "GET" }, onSuccess, OnError);
}
function getVideosSectionList(onSuccess, OnError) {
  receiver(`/api//section/video/`, { method: "GET" }, onSuccess, OnError);
}


function getStatistics(page = 1, limit = 10, onSuccess, OnError) {
  receiver(
    `/api/analys?limit=${limit}&page=${page}`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}
function getPresentation(page = 1, limit = 10, onSuccess, OnError) {
  receiver(
    `/api/presentation?limit=${limit}&page=${page}`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}
function getVideoSection(section, onSuccess, OnError) {
  receiver(
    `/api/video/all/${section}`,
    { method: "GET", credentials: "include", redirect: "follow" },
    onSuccess,
    OnError
  );
}

function getSection(onSuccess, OnError) {
  receiver(`/api/section`, { method: "GET" }, onSuccess, OnError);
}

function getVideo(id, onSuccess, OnError) {
  receiver(
    `/api/video/${id}`,
    { method: "GET", credentials: "include", redirect: "follow" },
    onSuccess,
    OnError
  );
}

function getMainPage(onSuccess, OnError) {
  receiver("/api/main-page", { method: "GET" }, onSuccess, OnError);
}

function getMembers(onSuccess, OnError) {
  receiver(
    "/api/member?limit=100&page=1",
    { method: "GET" },
    onSuccess,
    OnError
  );
}
function getCharts(page = 1, limit = 20, onSuccess, OnError) {
  receiver(
    `/api/viewer/single/chart?limit=${limit}&page=${page}`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}
function getChartProduct(onSuccess, OnError) {
  receiver(`/api/single/product/chart`, { method: "GET" }, onSuccess, OnError);
}
function getMapProduct(onSuccess, OnError) {
  receiver(`/api/view/maps/product`, { method: "GET" }, onSuccess, OnError);
}

function getChartProductCountry(onSuccess, OnError) {
  receiver(`/single/product/country/chart`,
    { method: "GET" },
    onSuccess,
    OnError
  );
}
function getchart(id, onSuccess, OnError) {
  receiver(
    `/api/chart/${id}`,
    { method: "GET", credentials: "include", redirect: "follow" },
    onSuccess,
    OnError
  );
}
export {
  getArticle,
  getArticles,
  getCompanies,
  getActivities,
  filterCompanies,
  filterArticles,
  getEvents,
  getCompany,
  getMainPage,
  getMembers,
  getMagazines,
  getMagazine,
  getVideos,
  getVideo,
  getMagazinesTest,
  getContenByMagazineId,
  getSection,
  getVideoSection,
  getRegions,
  getCharts,
  getchart,
  getChartProduct,
  getChartProductCountry,
  getMapProduct,
  getStatistics,
  filterStatics,
  getStatistic,
  getCrops,
  getVideosSectionList,
  getPresentation,
  getPresentationId
};
