import React from "react";
import Loader from "../Loader/Loader";

function isBottom(ref) {
  if (!ref || !ref.current) {
    return false;
  }
  return ref.current.getBoundingClientRect().bottom <= window.innerHeight;
}

const InfiniteScroll = ({
  onBottomHit,
  isLoading,
  hasMoreData,
  loadOnMount,
  children,
}) => {
  const [initialLoad, setInitialLoad] = React.useState(true);
  const contentRef = React.useRef(null);
  const isFunction = typeof onBottomHit === "function";

  React.useEffect(() => {
    if (loadOnMount && initialLoad) {
      isFunction && onBottomHit();
      setInitialLoad(false);
    }
  }, [onBottomHit, loadOnMount, initialLoad]);

  React.useEffect(() => {
    const onScroll = () => {
      if (!isLoading && hasMoreData && isBottom(contentRef)) {
        isFunction && onBottomHit();
      }
    };
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, [onBottomHit, isLoading, hasMoreData]);

  return <div ref={contentRef}>{isLoading ? <Loader /> : children}</div>;
};

export default React.memo(InfiniteScroll);
