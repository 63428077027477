import React from "react";
class SidebarContentRight extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="col-sm-12 col-md-3 content-text right-content screen-content" style={{ top: "90px", backgroundImage: "url(" + "../assets/img/kolonka.jpg" + ")", backgroundSize: "175%", backgroundRepeat: "repeat-y", borderRadius: "30px", padding: "20px" }} >
        <p >Muassis:"Annexure Financial Solution UZB" masulyati cheklangan  jamiyati</p>
        <img
          src="../assets/icons/logo-w-text.svg"
          className="conten-logo-img"
        />
        <p style={{ textAlign: "center", fontWeight: "bold" }}>{this.props.name}</p>
        <hr style={{ color: "red", height: "3px" }} />
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>Bosh muharrir: </p>
        <p>Goʼzal PIRJАNOVА-ShIRINOVA</p>
        <p style={{ fontWeight: "bold",marginTop: "10px" }}>Kuzatuv kengashi: </p>
        <p>Oksana ANTONOVA</p>
        <p>Husan HAYDAROV</p>
        <p>Rezida ERDMАN</p>
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>Fotograflar: </p>
        <p>Po‘lat YULDASHEV </p>
        <p>Ulug‘bek MEYLIKOV</p>
        <p>Rustam IBRAGIMOV</p>
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>Dizayner: </p>
        <p>Isroil TURG‘UNOV</p>
        <p>Hamidxon BOBOJONOV</p>
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>Veb dizayner: </p>
        <p>Asliddin NAZAROV</p>
        <hr style={{ color: "red", height: "3px" }} />
        <p style={{ fontWeight: "bold" }}>Manzil: 100000,  </p>
        <p>O‘zbekiston Respublikasi,
          Toshkent shahri,
          Mirzo Ulug‘bek tumani,
          Mustaqillik shoh ko‘chasi, 59-uy. </p>
        <p style={{ fontWeight: "bold" }}>Telefon: 78-120-11-21 </p>
        <hr style={{ color: "red", height: "3px" }} />
        <p>
          Mualliflar fikri tahririyat nuqtayi
          nazaridan farq qilishi mumkin.
          Ko‘chirib bosilganda nashr nomi
          ko‘rsatilishi shart.
          <br />
          Nashr O‘zbekiston Respublikasi
          Prezidenti Administratsiyasi huzuridagi
          Axborot va ommaviy kommunikatsiyalar
          agentligi tomonidan 2021-yil 5-aprelda
          1169 raqami bilan ro‘yxatga olingan.
        </p>
      </div>
    );
  }
}

export default SidebarContentRight;
