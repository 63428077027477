import React, { useState } from 'react'
import { Fetch } from 'react-request'
import Highmaps from 'highcharts/highmaps'
import { useHistory } from 'react-router-dom';
import Select from 'react-select'
import axios from "axios";
import { HighchartsMapChart, HighmapsProvider, Tooltip, MapSeries } from 'react-jsx-highmaps'
const MapCard = () => {
  const history = useHistory();
  const [table, setData] = useState([]);
  const [options, setOptions] = useState(0);
  const [eksport, setEksport] = useState(0);
  const [name, setName] = useState(0);
  const [region, setRegion] = useState(0);
  const dataList = [
    ['uz-fa', "#CDF0EA"],
    ['uz-tk', "#FDEFEF"],
    ['uz-an', "#CDF0EA"],
    ['uz-ng', "#CDF0EA"],
    ['uz-ji', "#FDEFEF"],
    ['uz-si', "#FDEFEF"],
    ['uz-ta', '#FDEFEF'],
    ['uz-bu', '#E1F1DD'],
    ['uz-kh', '#FFD9C0'],
    ['uz-qr', '#FFD9C0'],
    ['uz-nw', '#E1F1DD'],
    ['uz-sa', '#E1F1DD'],
    ['uz-qa', '#FAF4B7'],
    ['uz-su', '#FAF4B7']
  ];

  let series = [
    {
      data: dataList,
      keys: ['hc-key', 'color'],
    },
  ];
  let eksports = [
    {
      label: 'Все',
      value: 'Все',
    },
    {
      label: 'Внутренный рынок',
      value: 'Внутренный рынок',
    },
    {
      label: 'Экспорт',
      value: 'Экспорт',
    },
    {
      label: 'Экспорт/внутренный рынок',
      value: 'Экспорт/внутренный рынок',
    },
  ];
  const getData = async (key) => {
    setRegion(key)
    setEksport(0)
    axios.post(`${process.env.REACT_APP_BASEURL}/api/crops/region`, { region: key })
      .then((res) => {
        setData(res.data);
        axios.post(`${process.env.REACT_APP_BASEURL}/api/crops/list`, { region: key })
          .then((response) => {
            setOptions(response.data)
          });
      })
  }
  let handleEksport = async (eksport) => {
    setEksport(eksport)
    setName(0)
    if (eksport.value == 'Все') {
      getData(region)
    } else {
      axios.post(`${process.env.REACT_APP_BASEURL}/api/crops/eksport`, { region: region, eksport: eksport.value })
        .then((response) => {
          setData([])
          setData(response.data)
        });
    }
  }

  let handleName = async (name) => {
    setName(name)
    setEksport(0)
    axios.post(`${process.env.REACT_APP_BASEURL}/api/crops/product`, { region: region, name: name.value })
      .then((response) => {
        setData([])
        setData(response.data)
      });
  }

  let handleClear = async () => {
    setName(0)
    setEksport(0)
  }

  const Redirect = (id) => {
    history.push(`/map/${id}`)
  }
  return (<>
    <div className="app" >
      <Fetch url="https://code.highcharts.com/mapdata/countries/uz/uz-all.geo.json">
        {({ fetching, data }) => {
          if (fetching) return <div>Loading…</div>
          if (data) {
            return (
              <>
                <HighmapsProvider Highcharts={Highmaps}>
                  <HighchartsMapChart map={data} series={series} chart={{ height: "80%" }}>
                    <MapSeries
                      name="Uzbekistan"
                      data={dataList}
                      color={'#3e7dbc'}
                      animation={{
                        defer: true
                      }}
                      onClick={function (data) {
                        let point = {
                          hckey: "",
                          value: ""
                        }
                        point = data.point.options;
                        getData(point["hc-key"])
                      }
                      }

                      tooltip={{
                        backgroundColor: 'none',
                        borderWidth: 0,
                        shadow: false,
                        useHTML: true,
                        padding: 0,
                        pointFormat:
                          ' <span style="font-size:22px">{point.name}</span> <br>',
                        positioner: function () {
                          return { x: 0, y: 250 };
                        }
                      }}
                      exporting={{
                        sourceWidth: 600,
                        sourceHeight: 500
                      }}
                      states={{
                        hover: {
                          color: '#3aabb7'
                        },
                        select: {
                          color: '#f0cf97'
                        }
                      }}
                      allowPointSelect={true}
                      dataLabels={{
                        enabled: true,
                        color: '#FFFFFF',
                        format: '{point.name}'
                      }}
                    />
                    <Tooltip />
                  </HighchartsMapChart>
                </HighmapsProvider>

                {table.length > 0 ?
                  <>
                    <div className='d-flex pb-3'>
                      <div className="col-lg-3 col-md-4 col-sm-4">
                        <Select options={options}
                          onChange={handleName}
                          value={name}
                          placeholder="Meva va sabzavot turi"
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-4">
                        <Select options={eksports} className="pl-1"
                          onChange={handleEksport}
                          value={eksport}
                          placeholder="Ichki bozor/Eksport"
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-4">
                        <button type="button" onClick={handleClear} className='ml-2 p-1 btn btn-success'>уборка</button>
                      </div>
                    </div>
                    <table class="table table-responsive  " >
                      <thead>
                        <tr>
                          <th scope="col">№</th>
                          <th scope="col">Имя</th>
                          <th scope="col">Тип</th>
                          <th scope="col">Назначения сорта:</th>
                          <th scope="col">Тип размера плода</th>
                          <th scope="col">Вес урожая (граммы)</th>
                          <th scope="col">
                            Производительность с/г</th>
                          <th scope="col">Срок годности (дней)</th>
                          <th scope="col">Картина</th>
                        </tr>
                      </thead>
                      <tbody>
                        {table.map((item, i) => (
                          <tr onClick={() => Redirect(item.id)} style={{ cursor: 'pointer' }}>
                            <td>{i + 1}</td>
                            <td className='text-truncate' style={{ maxWidth: "200px" }}>{item.name}</td>
                            <td className='text-truncate' style={{ maxWidth: "200px" }}>{item.sort}</td>
                            <td className='text-truncate' style={{ maxWidth: "200px" }}>{item.eksport}</td>
                            <td className='text-truncate' style={{ maxWidth: "120px" }}>{item.volume}</td>
                            <td className='text-truncate' style={{ maxWidth: "120px" }}>{item.weight}</td>
                            <td className='text-truncate' style={{ maxWidth: "100px" }}>{item.weight_harvest}</td>
                            <td className='text-truncate' style={{ maxWidth: "100px" }}>{item.save_date}</td>
                            <td>
                              <img src={item.image ? item.image : '/assets/img/fruits.jfif'} height="60px" width="60px" alt="" />
                            </td>
                          </tr>
                        ))
                        }
                      </tbody>
                    </table> </> : <></>
                }
              </>
            )
          }

          return null
        }}
      </Fetch>

    </div>
  </>
  );
}

export default MapCard
