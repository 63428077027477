export function stripScripts(htmlString) {
  const div = document.createElement("div");
  const replacedString = htmlString.replace(/<script.*>.*<\/script>/, " ");
  div.innerHTML = replacedString;
  const scripts = div.getElementsByTagName("script");
  for (let index = 0; index < scripts.length; index++) {
    const element = scripts[index];
    element.parentNode.removeChild(scripts[index]);
  }
  return div.innerHTML;
}

export function stripScriptP(htmlString) {
  const replacedString = htmlString.split("</p>")[0];
  let str = replacedString.replace(/(<([^>]+)>)/ig, '')
  return str.split('.')
}


export function queryParamsBuilder(params) {
  if (!params) {
    throw new Error("Params cannot be blank");
  }
  let queryParams = "";
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        queryParams += `&${key}[]=${value.join(",")}`;
      }
    } else {
      queryParams += `&${key}=${value}`;
    }
  }
  return queryParams;
}

export default async function receiver(url, options, onSuccess, onError) {
  if (url.trim().length <= 0) {
    throw new Error("Url option cannot be blank");
  }
  if (!options) {
    throw new Error("Options cannot be blank");
  }

  try {
    const request = await fetch(
      `${process.env.REACT_APP_BASEURL}${url}`,
      options
    );
    if (!request.ok && request.status >= 400 && request.status < 500) {
      const errorBody = await request.json();
      onError(errorBody);
      return errorBody;
    }
    if (!request.ok && request.status >= 500) {
      throw new Error("Internal server Error");
    }
    const response = await request.json();

    if (typeof onSuccess === "function") onSuccess(response);
    return response;
  } catch (error) {
    console.log("Error response body", error);
    if (typeof onError === "function") onError(error);
    return error;
  }
}

export function createFormData(data) {
  const formData = new FormData();
  if (data && Object.keys(data).length === 0 && data.constructor === Object) {
    return formData;
  }

  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value) || (value && value.constructor === Object)) {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  }
  return formData;
}

export function createReadableDate(dateString) {
  const parsedDate = new Date(dateString).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' });
  return parsedDate;
}

export function exportWord(element, filename = "") {
  const mywindow = window.open("", "ARTICLE");

  mywindow.document.write("<html><head>");

  mywindow.document.write("</head><body>");

  mywindow.document.body.append(element);

  const title = mywindow.document.querySelector(".article-title")
  title.style.marginTop = 0;
  // let docChar = 0;
  // let charLenght = element.length;
  // while (charLenght !== 0) {
  //   mywindow.document.write(element[docChar]);
  //   docChar++;
  //   charLenght--;
  // }

  mywindow.document.write("</body></html>");

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  mywindow.print();
  // mywindow.close();

  return true;
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function priorityNavigation() {
  const nav = document.getElementById("priority-nav");
  // TODO: add button
  const moreBtn = nav.querySelector("#priority-nav span.more-dots");
  const navList = nav.querySelector("#priority-nav .nav-list");
  const navHiddenList = nav.querySelector("#priority-nav .hidden-nav-list");

  let numOfItems = 0;
  let totalSpace = 0;
  let breakWidths = [];

  let availableSpace, numOfVisibleItems, requiredSpace;

  for (let i = 0; i < navList.children.length; i++) {
    const element = navList.children.item(i);
    totalSpace += element.offsetWidth;
    numOfItems += 1;
    breakWidths.push(totalSpace);
  }

  function check() {
    // Get instant state
    availableSpace = navList.offsetWidth - 10;
    numOfVisibleItems = navList.children.length;
    requiredSpace = breakWidths[numOfVisibleItems - 1];

    // There is not enought space
    if (requiredSpace > availableSpace - 40) {
      navHiddenList.appendChild(navList.lastChild);
      numOfVisibleItems -= 1;

      check();
      // There is more than enough space
    } else if (availableSpace - 40 > breakWidths[numOfVisibleItems]) {
      navList.appendChild(navHiddenList.lastChild);
      numOfVisibleItems += 1;
    }
    // Update the button accordingly
    moreBtn.setAttribute("count", numOfItems - numOfVisibleItems);
    if (numOfVisibleItems === numOfItems) {
      moreBtn.classList.add("hidden");
    } else moreBtn.classList.remove("hidden");
  }

  window.addEventListener("resize", check);
  moreBtn.addEventListener("click", function () {
    if (navHiddenList.classList.contains("hidden")) {
      moreBtn.classList.add("open");
    } else {
      moreBtn.classList.remove("open");
    }
    navHiddenList.classList.toggle("hidden");
  });

  check();
}
