import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "normalize.css";
import "../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";
import App from "./App";
import 'react-notifications/lib/notifications.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
//import Snowfall from "react-snowfall";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <BrowserRouter>
    <App />
   {/*   <Snowfall
          color="#fff"
          snowflakeCount={300}
          radius = {[1.5, 4.0]}
      />*/}
  </BrowserRouter>
  , document.getElementById('root')
)

reportWebVitals();
