import React from "react";
import { Link } from "react-router-dom";
function ContentListCard(props) {
  const { content } = props;
  if (!content) {
    return null;
  }
  const { id, name, author, image, pdf, page } = content;
  const imagePreview = image?.includes("http")
    ? image
    : "https://dummyimage.com/480/ced4da/6c757d.jpg";
  return (
    <>
    
      <Link
        to={{
          pathname: "/contentone/" + id,
          state: {
            id: id,
            name: name,
            author:author,
            pdf: pdf,
            image: image,
            page: page,
          },
        }}
      >
        <div className="my-3 d-flex flex-wrap-inline  align-items-center ">
          <img
            className="content-img mr-4"
            src={imagePreview}
            alt={name}
          />
          <span className="content-name">
            {name}
          </span>
          <h1 className="content-dot"></h1>
          <span className="content-page" >
            {page}
          </span>
        </div>
      </Link>
    </>
  );
}

export default ContentListCard;
